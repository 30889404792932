.customer-account-login {
  .block.block-customer-login {
    background-color: $c-pink;

    .actions-toolbar {
      position: relative;

      @extend .clearfix;

      .primary {
        display: inline-block;
        margin: 0;
      }

      .secondary {
        position: absolute;
        bottom: 0;
        right: 0;
      }

      .action.remind {
        font-size: $f-small;
        line-height: $lh-small;
        text-decoration: underline;
        color: $c-dark;
      }
    }
  }
}