.contact-index-index {

  @include min-screen($bp-desktop-s) {

    .column.main {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    .contact-page-left {
      flex: 1;
      margin-right: $trim--l / 2;
    }

    .contact-page-right {
      flex: 1;
      margin-left: $trim--l / 2;
    }
  }

  .block-content {
    line-height: 1.5em;
  }

  .column.main {

    .block {
      .block-content {
        font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
        font-weight: 300;
      }
    }

    .form.contact {
      background-color: $c-pink;

      @include max-screen($bp-desktop-s) {
        margin-bottom: $trim;
      }

      .actions-toolbar {
        text-align: right;

        .primary {
          float: none;

          @include max-screen($bp-desktop-s) {
            display: block;
          }

          .btn {
            width: 100%;
          }
        }
      }
    }

    .iframe-container {

      @include max-screen($bp-desktop-s) {
        height: 348px;
      }

      @include min-screen($bp-desktop-s) {
        height: 100%;
      }
    }
  }
}