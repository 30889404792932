.block-promo {
  position: relative;

  @include max-screen($bp-tablet) {
    padding-top: $trim--xxl;
    padding-bottom: $trim--xxl;
  }

  @include screen($bp-tablet,$bp-desktop-s) {
    padding-top: 76px;
    padding-bottom: 76px;
  }

  @include min-screen($bp-desktop-s) {
    padding-top: 121px;
    padding-bottom: 121px;
  }

  &.promo-main {
    background-color: #202C59;
    background-repeat: no-repeat;
    background-size: cover;

    .box-promo {
      color: $c-white;

      p {
        color: $c-text--light;
      }
    }
    
    .btn--scroll {
      position: absolute;
      bottom: $trim;
      left: 50%;
      transform: translateX(-50%);
      cursor: default;

      @include max-screen($bp-desktop-s) {
        display: none;
      }
    }
  }

  .box-promo {

    @include screen($bp-tablet,$bp-desktop-s) {
      max-width: 48%;
    }

    @include min-screen($bp-desktop-s) {
      max-width: 35%;
    }
  }
}