.page-header {

  border: none;
  margin: 0;

  .panel.content {
    @include min-screen($bp-desktop-s) {
      border-bottom: 1px solid $c-border;
    }
  }

  .panel.header {

    .header.links {
      color: $c-dark;

      @include min-screen($bp-desktop-s) {
        display: flex;
        width: 100%;
        align-items: center;
      }

      li {
        order: 5;
        border-left: 1px solid $c-border;
        padding: 0 $trim;
        margin: 0;

        &.greet.welcome {
          font-weight: 700;
          order: 1;
          border-left: none;
          padding-left: 0;
        }

        &.phone {
          order: 2;
          margin-right: auto;
        }

        &.language-change {
          order: 3;
          border-left: none;

          .action.toggle:after {
            content: '';
          }

        }

        &:nth-child(2) {
          padding-right: 0;
        }
      }

      a {
        color: $c-dark;
        text-decoration: none;
      }
    }
  }

  .header.content {

    @include max-screen($bp-tablet) {
      padding-right: $trim;
      padding-left: $trim;
    }

    @include screen($bp-tablet,$bp-desktop) {
      padding-right: $trim--xxl;
      padding-left: $trim--xxl;
      max-width: calc(#{$s-wrapper} + (#{$trim--xxl} * 2));
      margin: 0 auto;
    }

    @include max-screen($bp-desktop) {
      padding-top: $trim--xs;
      padding-bottom: $trim;
    }

    @include min-screen($bp-desktop-s) {
      padding-top: $trim--l;
      padding-bottom: $trim--l;

      display: flex;
      align-items: center;
    }

    .logo {
      margin: 0;

      @include min-screen($bp-desktop-s) {
        flex: 1;
        order: 1;
      }

      img {

        @include max-screen($bp-desktop-s) {
          max-width: 109px;
        }
      }
    }

    .phone-mobile {
      cursor: pointer;
      position: absolute;
      top: 19px;

      @include max-screen($bp-tablet) {
        right: 145px;

        &.translatex {
         right: 182px;
        }
      }

      @include screen($bp-tablet,$bp-desktop-s) {
        right: 165px;

        &.translatex {
          right: 202px;
        }
      }

      @include min-screen($bp-desktop-s) {
        display: none;
      }

      span {
        &:before {
          content: url(../images/icons/icon-phone.svg);
          width: 26px;
          height: 26px;
          font-size: 26px;
          line-height: 18px;
        }
      }
    }

    .block-search {
      margin: 0;

      @include min-screen($bp-desktop-s) {
        flex: 2;
        order: 2;
      }

      .label {
        margin-right: 17px;
        margin-top: $trim--xs;

        &:before {
          color: $c-dark;
          width: 26px;
          height: 26px;
          font-size: 26px;
          line-height: 26px;
        }
      }

      .control {
        border: none;
      }

      .action.search {

        &:before {
          content: url(../images/icons/icon-search.svg);
          border-radius: 6px;
          background-color: $c-orange--light;
          font-size: 19px;
          line-height: 34px;
          padding: 0 14px;
        }
      }
    }

    .minicart-container {
      @include min-screen($bp-desktop-s) {
        flex: 1;
        order: 3;
        text-align: right;
      }
    }

    .minicart-wrapper{
      margin: 0;

      @include min-screen($bp-desktop-s) {
        border: 1px solid $c-border;
        border-radius: $b-radius;
      }

      .action.showcart {
        color: $c-dark;

        @include max-screen($bp-desktop-s) {
          margin-top: $trim--xs;
          margin-right: $trim;
        }

        @include min-screen($bp-desktop-s) {
          display: flex;
          align-items: center;
          padding: 6px $trim;
        }

        &:before {
          content: url(../images/icons/icon-basket.svg);
          width: 26px;
          height: 26px;
          font-size: 26px;
          line-height: 18px;

          @include min-screen($bp-desktop-s) {
            order: 1;
            margin: 0 4px;
          }
        }

        .text,
        .price {
          @include max-screen($bp-desktop-s) {
            display: none;
          }
        }

        .text {
          width: auto;
          height: auto;
          position: static;
          font-weight: 700;
        }

        .price {
          color: $c-opacity;
          @include min-screen($bp-desktop-s) {
            order: 2;
          }
        }

      }
    }

    .nav-toggle {
      float: right;
      position: static;
      margin-top: $trim--xs;

      &:before {
        color: $c-dark;
        width: 26px;
        height: 26px;
        font-size: 26px;
        line-height: 26px;
      }
    }
  }
}

// Menu icon when nav is open
html.nav-open {
  .page-header {
    .header.content {
      .nav-toggle {
        max-width: 26px;
        overflow: hidden;

        &:before {
          margin-left: -5px;
          font-size: 36px;
          width: 36px;
        }
      }
    }
  }
}