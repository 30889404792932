.block-quote {
  border-top: 1px solid $c-border;

  @include min-screen($bp-desktop){
    min-width: $s-wrapper;
  }

  h2 {
    color: $c-dark;
  }

  .box--center {
    margin: 0;

    .collapse-head {
      position: relative;
      padding-right: 29px;

      &:after {
        content: url(../images/icons/icon-down-dark.svg);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .header-p {
      margin-bottom: 0;
    }

    &.active {

      .collapse-head {
        &:after {
          transform: translateY(-50%) rotate(180deg);
        }
      }

      .header-p {
        margin-bottom: $trim;
      }
    }
  }

  form {

    .field {

      @include min-screen($bp-tablet) {

        &:not(.comment):not(.note) {

          width: calc(50% - #{$trim--l} / 2);

          &:nth-child(odd) {
            float: left;
            clear: left;
          }
          &:nth-child(even) {
            float: right;
            clear: right;
          }
        }
      }
    }

    .actions-toolbar {
      
      .primary {

        @include min-screen($bp-tablet) {
          float: right;
        }
      }
    }
  }
}