.btn {
  display: inline-block;
  border-radius: $b-radius;
  box-sizing: border-box;
  text-align: center;
  font-weight: 700;
  padding: 9px $trim;

  @include max-screen($bp-tablet) {
    width: 100%;
  }

  &--primary {
    @include button($c-blue, rgba(56,54,127,0.2));
  }
  &--primary_revert {
    color: $c-blue !important;
    background-color: #EFF7FC;
  }
  &--secondary {
    @include button($c-orange, rgba(255,143,0,0.2));
  }
  &--tertiary {
    @include button($c-green, rgba(44,209,138,0.2));
  }
  &--default {
    @include button($c-dark, rgba(47,47,47,0.2));
  }

  &--scroll {
    display: block;
    width: 26px;
    height: 48px;
    background-image: url('../images/icons/icon-scroll.svg');
    background-color: transparent;
    background-repeat: no-repeat;
  }

  &--corner {

    @include min-screen($bp-tablet) {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

