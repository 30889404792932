.block-special {
  .box--center {
    background: $c-highlight;
    border: none;
  }

  h3 {
    color:  $c-blue;
  }

  p:last-child {
    margin: 0;
  }
}