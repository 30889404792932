.block-reviews {

  h2 {
    color: $c-blue;
  }

  .reviews-content {
    position: relative;
  }

  .review-item {
    border: 1px solid $c-border;

    @include max-screen($bp-tablet){

      padding: $trim--s;
      @include float-item(1,$trim--s);

      &:nth-child(n+3) {
        display: none;
      }
    }

    @include screen($bp-tablet,$bp-desktop-s){

      padding: $trim--s;
      @include float-item(3,$trim--l);
    }

    @include min-screen($bp-desktop-s){

      padding: $trim--xl;
      @include float-item(3,$trim--l);
    }

    .stars {

      @include max-screen($bp-desktop-s) {
        margin-bottom: $trim--xs;
      }
      @include min-screen($bp-desktop-s) {
        margin-bottom: $trim--s;
      }

      .star {
        &:before {
          content: url(../images/icons/icon-star.svg);
        }
      }
    }

    h3,
    .h3 {
      color: $c-blue;
    }

    .author {
      display: block;
      font-weight: 700;
    }

    &s {
      display: flex;
      flex-wrap: wrap;
    }
  }
}