.our-services-extend {
  border-bottom: 1px solid $c-border;

  .box-holder {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .box {
    @include max-screen($bp-tablet) {
      margin-bottom: $trim--s;
    }
    @include screen($bp-tablet,$bp-desktop-s) {
      -webkit-flex-basis: 48.5%;
      flex-basis: 48.5%;
    }
    @include min-screen($bp-desktop-s) {
      -webkit-flex-basis: 31.8%;
      flex-basis: 31.8%;
    }
    
    &:last-child {
      @include max-screen($bp-desktop-s) {
        display: none;
      }
    }
  }

  .box--classic {
    border: none;
    background-color: $c-dark;
    color: $c-text--reverse;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }

  h2 {
    color: $c-white;

    @include max-screen($bp-desktop-s) {
      margin: 0 0 $trim--s;
    }

    @include min-screen($bp-desktop-s) {
      margin: 0 0 $trim;
    }
  }

  figure {
    float: left;
    width: 100%;

    @include max-screen($bp-desktop-s) {
      margin-bottom: $trim;
    }

    @include min-screen($bp-desktop-s) {
      margin-bottom: $trim--l;
    }

    img {
      width: 100%;
    }
  }
}