.sections.nav-sections {

  .ves-megamenu {
    .navigation {
      .nav-item {
        &.subhover>.submenu>.submenu-inner {
          @extend .wrapper;
          @include max-screen($bp-desktop-s) {
            padding-left: 0;
            padding-right: 0;
          }
          img {
            display: block;
            margin: 0 auto;
          }
        }

        a {
          @include max-screen($bp-tablet) {
            padding: 0 $trim;
          }
          @include screen($bp-tablet,$bp-desktop-s) {
            padding: 0 $trim--xxl;
          }
          @include max-screen($bp-desktop-s) {
            line-height: 40px;
          }
          @include min-screen($bp-desktop-s) {
            line-height: 48px;
          }
        }
      }

      .level0 {
        &.current {
          > a {

            @include min-screen($bp-desktop-s) {
              position: relative;

              &:before {
                content: '';
                position: absolute;
                bottom: 0;
                height: 2px;
                background-color: $c-orange;
                width: 100%;
              }
            }
          }
        }
        .submenu {
          .submenu-inner {
            .item-footer {
              @include min-screen($bp-desktop-s) {
                margin-bottom: 32px;

                a {
                  line-height: inherit;
                  display: inline-block;

                  &:hover {
                    background-color: $c-orange;
                  }
                }
              }
            }
          }
          .active {
            > a {
              color: $c-white;
              border: none;
              background-color: $c-nav--bg;
            }
          }
        }
      }
      .item-sidebar{
        &.right-sidebar {
          float: right;
        }
      }
    }

    &.ves-offcanvas {
      .opener {
        @include max-screen($bp-tablet) {
          margin-right: 10px;
        }
        @include screen($bp-tablet,$bp-desktop-s) {
          margin-right: 30px;
        }
      }
    }

    &.ves-offcanvas,
    &.ves-dhorizontal {
      .level0 {
        .submenu .nav-item>a {
          @include min-screen($bp-desktop-s) {
            line-height: 23px;
            font-size: 18px;
            padding: 0;
            margin-bottom: $trim;
          }
        }
      }

      .level2 .mega-col .nav-item>a {
        @include min-screen($bp-desktop-s) {
          line-height: 16px;
          font-size: 14px;
          margin-bottom: $trim--xs;
          color: $c-text--reverse;
        }
      }
    }
  }
  
  // Disable extra Menu Item on Desktop
  .home-account-link {
    @include min-screen($bp-desktop-s) {
      display: none !important;
    }
  }
}

//Desktop nav items height
@include min-screen($bp-desktop-s) {
  .ves-megamenu {
    .navigation {
      .mega-col {
        .child {
          &-network-hardware {
            &:nth-child(1) {
              min-height: 159px;
            }
            &:nth-child(2) {
              min-height: 97px;
            }
            &:nth-child(3) {
              min-height: auto;
            }
          }
          &-default-category {
            min-height: 97px;
          }
          &-network-accessories {
            min-height: 56px;
          }
        }
      }
    }
  }
}