.block-accreditations {

  border-top: 1px solid $c-border;

  h2 {
    color: $c-dark;
    margin: 0 0 $trim;
  }

  .accred-info {
    @include max-screen($bp-desktop-s) {
      margin-bottom: $trim--l;
    }
    @include min-screen($bp-desktop-s) {
      margin-bottom: $trim--xl;
    }
  }

  .accred-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
  }

  .accred-item {
    border: 1px solid $c-border;

    @include max-screen($bp-tablet) {
      //@include float-item(2,$trim);
      padding: $trim--xs;

      -webkit-flex-basis: calc(50% - 8px);
      flex-basis: calc(50% - 8px);
      margin-bottom: $trim--s;
      margin-right: $trim;

      &:nth-child(even) {
        margin-right: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
    @include screen($bp-tablet,$bp-desktop-s) {
      //@include float-item(3,$trim--l);
      padding: $trim--s;

      -webkit-flex-basis: calc(33.333% - 16px);
      flex-basis: calc(33.333% - 16px);

      margin-bottom: $trim--l;
      margin-right: $trim--l;

      &:nth-child(3n+3) {
        margin-right: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
    @include min-screen($bp-desktop-s) {
      //@include float-item(4,$trim--l);
      padding: $trim--xl;

      -webkit-flex-basis: calc(25% - 21px);
      flex-basis: calc(25% - 18px);

      margin-bottom: $trim--l;
      margin-right: $trim--l;

      &:nth-child(4n+4) {
        margin-right: 0;
      }

      &:nth-child(n+5) {
        margin-bottom: 0;
      }
    }

    h4 {
      @include max-screen($bp-desktop-s) {
        margin: 0;
      }
    }

    p {
      margin: 0;

      @include max-screen($bp-desktop-s) {
        display: none;
      }
    }
  }

  .accred-logo {
    font-size: 0;
    line-height: 0;

    img {
      @include max-screen($bp-tablet) {
        max-height: 30px;
        margin-bottom: $trim--xs;
      }
      @include min-screen($bp-tablet) {
        max-height: 40px;
        margin-bottom: $trim--s;
      }
    }
  }
}