.block-recently-viewed,
.block-suggested-products {

  border-top: 1px solid $c-border;

  h2 {
    color: $c-blue;
  }

  .product-items {

    display: flex;
    flex-wrap: wrap;

    .product-item {

      width: 100%;
      margin-bottom: 24px;
      
      @include min-screen($bp-tablet) {
        
        margin-right: 24px;

      }

      @include screen($bp-tablet,$bp-desktop-s) {
        width: calc(33.3% - 18px);

        &:nth-child(3n+3) {

          margin-right: 0;

        }

        &:nth-child(4n+4) {

          display: none;

        }
      }

      @include min-screen($bp-desktop-s) {
        width: calc(25% - 18px);

        &:nth-child(4n+4) {

          margin-right: 0;

        }
      
      }

      &-image {
        text-align: center;
      }

      &-info {

        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

      }

      &-name {

        min-height: 36px;

      }

    }

  }

}