.checkout-onepage-success {

  .column.main {

    border: 1px solid $c-border;
    background-color: $c-pink;

    @include max-screen($bp-desktop-s) {
      padding: $trim;
      margin: $trim 0 $trim--l;
    }

    @include min-screen($bp-desktop-s) {
      padding: $trim--xl;
      margin: $trim--xl 0 $trim--xl * 2;
    }

    a {
      color: inherit;
    }

    .actions-toolbar {
      .primary {
        text-align: left;
      }
    }

    .action.btn {
      width: auto !important;
    }
  }

  #registration {
    border-bottom: 1px solid $c-border;

    @include max-screen($bp-desktop-s) {
      padding-bottom: $trim;
      margin-bottom: $trim;
    }

    @include min-screen($bp-desktop-s) {
      padding-bottom: $trim--xl;
      margin-bottom: $trim--xl;
    }

    .title {
      font-size: 18px;
      font-weight: 700;
    }
  }

  .checkout-success {
    .title {
      font-size: 24px;
      line-height: 24px;
      font-weight: 700;

      @include max-screen($bp-tablet) {
        margin-bottom: $trim--s;
      }

      @include screen($bp-tablet,$bp-desktop-s) {
        margin-bottom: 14px;
      }

      @include min-screen($bp-desktop-s) {
        margin-bottom: $trim;
      }
    }
  }
}