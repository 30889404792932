// Magento Product listing
.products.grid {

  .product-items {
    display: -webkit-flex;
    display: flex;

    flex-flow: row wrap;
    -webkit-flex-flow: row wrap;

    .product-item {
      @include max-screen($bp-tablet){
        @include flex-item(2,$trim);
        margin-bottom: 14px;
      }

      @include screen($bp-tablet,$bp-desktop-s){
        @include flex-item(3,$trim--l);
        margin-bottom: $trim--m;
      }

      @include min-screen($bp-desktop-s){
        @include flex-item(5,$trim--l);
        margin-bottom: $trim--l;
      }

      .product-item-info {
        height: 100%;

        display: -webkit-flex;
        display: flex;

        flex-flow: column nowrap;
        -webkit-flex-flow: column nowrap;

        @include min-screen($bp-desktop-s){

          &:hover {

            cursor: pointer;
            box-shadow: #e2e2e2 2px 2px 9px;
          
          }

        }

        .price-box {
          margin-top: auto;
        }
      }
    }
  }
}

// MyNextBox product listing
.product-image-photo-container {
  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
}