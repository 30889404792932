.review-form .review-control-vote:before,
.review-form .review-control-vote label:before {
  line-height: 16px
}

#product-reviews {

  .rating-summary {
    margin-left: 16px;
    display: inline-block;
  }

  .review-toolbar {
    border-top: none !important;
  }

  .review-title {
    margin: 0px !important;
  }

  .review-post {

    .rating-summary {
      margin: 0;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid $c-border;

      @include max-screen($bp-tablet){
        padding-bottom: $trim--m;
        margin-bottom: $trim--m;
      }

      @include screen($bp-tablet,$bp-desktop-s){
        padding-bottom: $trim--l;
        margin-bottom: $trim--l;
      }

      @include min-screen($bp-desktop-s){
        padding-bottom: $trim--xl;
        margin-bottom: $trim--xl;
      }
    }
  }

  .review-stars {

    @include max-screen($bp-tablet){
      margin-bottom: $trim--s;
    }

    @include min-screen($bp-tablet){
      margin-bottom: $trim;
    }

    span {
      &:before {
        content: url(../images/icons/icon-star.svg);
      }
    }
  }

  .review-comment {
    clear: both;
    @include max-screen($bp-tablet){
      margin-bottom: $trim--s;
    }

    @include min-screen($bp-tablet){
      margin-bottom: $trim;
    }
  }
}


.product-page-tabs {

  .box--center {

    &.product-reviews {
      position: relative;

      h3 {
        @include max-screen($bp-tablet){
          margin-bottom: 40px;
        }

        @include screen($bp-tablet,$bp-desktop-s){
          margin-bottom: 24px;
        }

        @include min-screen($bp-desktop-s){
          margin-bottom: 26px;
        }
      }
    }
  }

  .reviews-all {
    position: absolute;

    @include max-screen($bp-tablet){
      top: $trim--m;
      right: $trim--m;
    }

    @include screen($bp-tablet,$bp-desktop-s){
      top: $trim--l;
      right: $trim--l;
    }

    @include min-screen($bp-desktop-s){
      top: $trim--xl;
      right: $trim--xl;
    }

    .product-reviews-summary {

      display: flex;
      align-items: center;
      margin: 0;

      @include max-screen($bp-tablet){
        flex-direction: column;
        align-items: flex-end;

        .rating-summary {
          left: 5px;
          order: 1;

          &-text {
            order: 2;
          }
        }
      }

    }

    .review-stars {
      direction: rtl;
      margin-left: $trim;
      margin-bottom: $trim--xs;

      @include max-screen($bp-tablet){
        display: block;
      }

      @include min-screen($bp-tablet){
        display: inline-block;
      }

    }
    
    small {
      float: left;
    }
  }
}

// .review-field-ratings .review-field-rating {
//   display: flex;
//   align-items: center;
// }

.review-list {
  border: 0 !important;
  padding: 0 !important;
  .toolbar-number {
    display: none;
  }
}

.review-control-vote label:before {
  color: $c-orange;
}

.rating-summary {
  .rating-result {
    >span:before {
      color: $c-orange;
    }
  }
}