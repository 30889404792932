// Global
.cms-page-view {

  .column.main {
    order: 2;
  }

  .columns .sidebar {
    order: 1;
  }

  @include min-screen($bp-desktop-s) {
    .column.main {
      width: percentage(9/12);
    }
    .columns .sidebar {
      width: percentage(3/12);
    }
  }

  .page-title {
    margin-bottom: 32px;
  }

  h2 {
    color: $c-blue;
    margin-top: 0;
  }

  .sub-title {
    font-size: 16px;
    color: #333;
  }

  .cms-navigation {
    padding: 0;
    list-style: none;
    width: 100%;

    li {
      background: #F9F9F9;
      margin-bottom: 1px;

      a {
        text-decoration: none;
        padding: 10px;
        display: block;
        width: 100%;
      }

      &.active {
        background: $c-blue;
        a {
          color: #fff;
        }
      }

      &:not(.active):hover {
        background: lighten($c-blue, 70%);
      }
    }
  }

}

// Home page
.cms-home {
  #maincontent {
    display: none;
  }
}

// About us
.cms-about-us {

  .box-container {
    @extend .clearfix;

    .box-column {

      @include min-screen($bp-desktop) {
        width: calc((100% / 3) - (#{$trim--l} * 2 / 3));
        float: left;

        &:not(:last-child) {
          margin-right: $trim--l;
        }
      }
    }
  }

  .box--classic {

    @include screen($bp-tablet,$bp-desktop) {
      width: calc(50% - (#{$trim--l} / 2));

      &:nth-child(odd) {
        float: left;
        margin-right: $trim--l;
      }

      &:nth-child(even) {

        float: right;
      }
    }
  }
}