@font-face {
  font-family: "Helvetica Neue Custom";
  src: url("../fonts/helveticaneue/HelveticaNeue-01.ttf") format("truetype"); }

@font-face {
  font-family: "Helvetica Neue Custom";
  src: url("../fonts/helveticaneue/HelveticaNeue-Bold-02.ttf") format("truetype");
  font-weight: bold; }

@font-face {
  font-family: "Helvetica Neue Custom";
  src: url("../fonts/helveticaneue/HelveticaNeue-BoldItalic-04.ttf") format("truetype");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: "Helvetica Neue Custom";
  src: url("../fonts/helveticaneue/HelveticaNeue-Italic-03.ttf") format("truetype");
  font-style: italic; }

body {
  font-family: 'Helvetica Neue Custom';
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  background-color: #FFFFFF;
  color: #505154; }

h1 {
  font-weight: 500;
  margin: 0 0 16px; }
  @media only screen and (max-width: 1023px) {
    h1 {
      font-size: 24px;
      line-height: 29px; } }
  @media only screen and (min-width: 1024px) {
    h1 {
      font-size: 32px;
      line-height: 39px; } }

h2 {
  font-weight: 500; }
  @media only screen and (max-width: 767px) {
    h2 {
      margin: 16px 0; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    h2 {
      margin: 24px 0; } }
  @media only screen and (max-width: 1023px) {
    h2 {
      font-size: 18px;
      line-height: 23px; } }
  @media only screen and (min-width: 1024px) {
    h2 {
      font-size: 24px;
      line-height: 29px;
      margin: 32px 0; } }

h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px; }
  @media only screen and (max-width: 1023px) {
    h3 {
      margin: 0 0 8px; } }
  @media only screen and (min-width: 1024px) {
    h3 {
      margin: 0 0 12px; } }

h4 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  margin: 0 0 8px; }

h5 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin: 0 0 12px; }

p {
  margin-bottom: 16px; }

a,
button {
  font-family: 'Helvetica Neue Custom';
  font-size: 14px;
  line-height: 18px;
  font-weight: 400; }

[data-role^="title"] {
  cursor: pointer; }

select.qty {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0 16px;
  background-image: url("../images/icons/icon-arrow-drop-down.svg");
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: right; }

.input-radio {
  width: 16px;
  height: 16px;
  display: block;
  border: 1px solid #DDDDDD;
  border-radius: 100%;
  padding: 3px; }
  .input-radio .selected {
    background: #393B40;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 100%; }

#parent_freeshipping_freeshipping .input-radio .freeshipping_freeshipping {
  background: #393B40;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%; }

figure {
  font-size: 0;
  line-height: 0; }

img {
  max-width: none; }

.iframe-container iframe {
  width: 100%;
  height: 100%; }

.clearfix:before, .cms-about-us .box-container:before, .customer-account-login .block.block-customer-login .actions-toolbar:before, .page-footer .footer.content:before, .clearfix:after, .cms-about-us .box-container:after, .customer-account-login .block.block-customer-login .actions-toolbar:after, .page-footer .footer.content:after {
  content: "";
  display: table;
  clear: both; }

.wrapper, .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .submenu-inner, .page-footer .footer.content {
  margin: 0 auto; }
  @media only screen and (max-width: 767px) {
    .wrapper, .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .submenu-inner, .page-footer .footer.content {
      padding-left: 16px;
      padding-right: 16px; } }
  @media only screen and (min-width: 768px) and (max-width: 1439px) {
    .wrapper, .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .submenu-inner, .page-footer .footer.content {
      padding-left: 36px;
      padding-right: 36px;
      max-width: calc(1116px + (36px * 2)); } }
  @media only screen and (min-width: 1440px) {
    .wrapper, .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .submenu-inner, .page-footer .footer.content {
      padding-left: 0;
      padding-right: 0;
      max-width: 1116px; } }
  @media only screen and (max-width: 767px) {
    .wrapper.container, .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .container.submenu-inner, .sections.nav-sections .ves-megamenu .navigation .page-footer .nav-item.subhover > .submenu > .submenu-inner.footer.content, .page-footer .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .submenu-inner.footer.content, .page-footer .footer.content {
      padding-top: 24px;
      padding-bottom: 24px; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .wrapper.container, .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .container.submenu-inner, .sections.nav-sections .ves-megamenu .navigation .page-footer .nav-item.subhover > .submenu > .submenu-inner.footer.content, .page-footer .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .submenu-inner.footer.content, .page-footer .footer.content {
      padding-top: 32px;
      padding-bottom: 32px; } }
  @media only screen and (min-width: 1024px) {
    .wrapper.container, .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .container.submenu-inner, .sections.nav-sections .ves-megamenu .navigation .page-footer .nav-item.subhover > .submenu > .submenu-inner.footer.content, .page-footer .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .submenu-inner.footer.content, .page-footer .footer.content {
      padding-top: 64px;
      padding-bottom: 64px; } }

.page-title {
  color: #1F2B5A; }
  @media only screen and (max-width: 767px) {
    .page-title {
      margin: 18px 0 24px; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .page-title {
      margin: 24px 0; } }
  @media only screen and (min-width: 1024px) {
    .page-title {
      margin: 32px 0 64px; } }

.column.main .block:not(.shipping):not(.discount):not(.items-in-cart),
.column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) {
  border: 1px solid #DDDDDD; }
  @media only screen and (max-width: 1023px) {
    .column.main .block:not(.shipping):not(.discount):not(.items-in-cart),
    .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) {
      padding: 16px; } }
  @media only screen and (min-width: 1024px) {
    .column.main .block:not(.shipping):not(.discount):not(.items-in-cart),
    .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) {
      padding: 32px; } }
  @media only screen and (max-width: 1023px) {
    .column.main .block:not(.shipping):not(.discount):not(.items-in-cart) > .block-title,
    .column.main .block:not(.shipping):not(.discount):not(.items-in-cart) > .fieldset .legend,
    .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) > .block-title,
    .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) > .fieldset .legend {
      margin-bottom: 16px; } }
  @media only screen and (min-width: 1024px) {
    .column.main .block:not(.shipping):not(.discount):not(.items-in-cart) > .block-title,
    .column.main .block:not(.shipping):not(.discount):not(.items-in-cart) > .fieldset .legend,
    .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) > .block-title,
    .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) > .fieldset .legend {
      margin-bottom: 24px; } }
  .column.main .block:not(.shipping):not(.discount):not(.items-in-cart) > .block-title a,
  .column.main .block:not(.shipping):not(.discount):not(.items-in-cart) > .block-title span,
  .column.main .block:not(.shipping):not(.discount):not(.items-in-cart) > .block-title strong,
  .column.main .block:not(.shipping):not(.discount):not(.items-in-cart) > .fieldset .legend a,
  .column.main .block:not(.shipping):not(.discount):not(.items-in-cart) > .fieldset .legend span,
  .column.main .block:not(.shipping):not(.discount):not(.items-in-cart) > .fieldset .legend strong,
  .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) > .block-title a,
  .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) > .block-title span,
  .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) > .block-title strong,
  .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) > .fieldset .legend a,
  .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) > .fieldset .legend span,
  .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) > .fieldset .legend strong {
    font-weight: 700;
    color: #1F2B5A; }
    @media only screen and (max-width: 1023px) {
      .column.main .block:not(.shipping):not(.discount):not(.items-in-cart) > .block-title a,
      .column.main .block:not(.shipping):not(.discount):not(.items-in-cart) > .block-title span,
      .column.main .block:not(.shipping):not(.discount):not(.items-in-cart) > .block-title strong,
      .column.main .block:not(.shipping):not(.discount):not(.items-in-cart) > .fieldset .legend a,
      .column.main .block:not(.shipping):not(.discount):not(.items-in-cart) > .fieldset .legend span,
      .column.main .block:not(.shipping):not(.discount):not(.items-in-cart) > .fieldset .legend strong,
      .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) > .block-title a,
      .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) > .block-title span,
      .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) > .block-title strong,
      .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) > .fieldset .legend a,
      .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) > .fieldset .legend span,
      .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) > .fieldset .legend strong {
        font-size: 18px;
        line-height: 23px; } }
    @media only screen and (min-width: 1024px) {
      .column.main .block:not(.shipping):not(.discount):not(.items-in-cart) > .block-title a,
      .column.main .block:not(.shipping):not(.discount):not(.items-in-cart) > .block-title span,
      .column.main .block:not(.shipping):not(.discount):not(.items-in-cart) > .block-title strong,
      .column.main .block:not(.shipping):not(.discount):not(.items-in-cart) > .fieldset .legend a,
      .column.main .block:not(.shipping):not(.discount):not(.items-in-cart) > .fieldset .legend span,
      .column.main .block:not(.shipping):not(.discount):not(.items-in-cart) > .fieldset .legend strong,
      .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) > .block-title a,
      .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) > .block-title span,
      .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) > .block-title strong,
      .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) > .fieldset .legend a,
      .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) > .fieldset .legend span,
      .column.main .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) > .fieldset .legend strong {
        font-size: 24px;
        line-height: 29px; } }

@media only screen and (max-width: 1023px) {
  .page-layout-2columns-right.blog-index-index .column.main, .page-layout-2columns-right.blog-post-view .column.main {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; } }

@media only screen and (min-width: 1024px) {
  .page-layout-2columns-right.blog-index-index .column.main, .page-layout-2columns-right.blog-post-view .column.main {
    width: 71%;
    padding-right: 70px;
    position: relative; }
    .page-layout-2columns-right.blog-index-index .column.main:after, .page-layout-2columns-right.blog-post-view .column.main:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 88px;
      width: 1px;
      background: #DDDDDD; } }

@media only screen and (max-width: 1023px) {
  .page-layout-2columns-right.blog-index-index .sidebar-additional, .page-layout-2columns-right.blog-post-view .sidebar-additional {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; } }

@media only screen and (min-width: 1024px) {
  .page-layout-2columns-right.blog-index-index .sidebar-additional, .page-layout-2columns-right.blog-post-view .sidebar-additional {
    width: 25%;
    padding: 0; } }

.products.grid .product-items {
  display: -webkit-flex;
  display: flex;
  flex-flow: row wrap;
  -webkit-flex-flow: row wrap; }
  @media only screen and (max-width: 767px) {
    .products.grid .product-items .product-item {
      width: calc((100% / 2) - (16px - 16px / 2));
      margin-bottom: 14px; }
      .products.grid .product-items .product-item:not(:nth-child(2n+2)) {
        margin-right: 16px; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .products.grid .product-items .product-item {
      width: calc((100% / 3) - (24px - 24px / 3));
      margin-bottom: 18px; }
      .products.grid .product-items .product-item:not(:nth-child(3n+3)) {
        margin-right: 24px; } }
  @media only screen and (min-width: 1024px) {
    .products.grid .product-items .product-item {
      width: calc((100% / 5) - (24px - 24px / 5));
      margin-bottom: 24px; }
      .products.grid .product-items .product-item:not(:nth-child(5n+5)) {
        margin-right: 24px; } }
  .products.grid .product-items .product-item .product-item-info {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    flex-flow: column nowrap;
    -webkit-flex-flow: column nowrap; }
    @media only screen and (min-width: 1024px) {
      .products.grid .product-items .product-item .product-item-info:hover {
        cursor: pointer;
        box-shadow: #e2e2e2 2px 2px 9px; } }
    .products.grid .product-items .product-item .product-item-info .price-box {
      margin-top: auto; }

.product-image-photo-container img {
  display: block;
  margin: 0 auto;
  max-width: 100%; }

.wrapper.container .block-container, .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .container.submenu-inner .block-container, .sections.nav-sections .ves-megamenu .navigation .page-footer .nav-item.subhover > .submenu > .submenu-inner.footer.content .block-container, .page-footer .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .submenu-inner.footer.content .block-container, .page-footer .footer.content .block-container {
  position: relative; }
  @media only screen and (min-width: 768px) {
    .wrapper.container .block-container > .btn, .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .container.submenu-inner .block-container > .btn, .sections.nav-sections .ves-megamenu .navigation .page-footer .nav-item.subhover > .submenu > .submenu-inner.footer.content .block-container > .btn, .page-footer .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .submenu-inner.footer.content .block-container > .btn, .page-footer .footer.content .block-container > .btn, .wrapper.container .page-footer .block.newsletter .block-container > button, .page-footer .block.newsletter .wrapper.container .block-container > button, .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .container.submenu-inner .page-footer .block.newsletter .block-container > button, .page-footer .block.newsletter .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .container.submenu-inner .block-container > button, .sections.nav-sections .ves-megamenu .navigation .page-footer .nav-item.subhover > .submenu > .submenu-inner.footer.content .block.newsletter .block-container > button, .sections.nav-sections .ves-megamenu .navigation .page-footer .block.newsletter .nav-item.subhover > .submenu > .submenu-inner.footer.content .block-container > button, .page-footer .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .submenu-inner.footer.content .block.newsletter .block-container > button, .page-footer .block.newsletter .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .submenu-inner.footer.content .block-container > button, .page-footer .footer.content .block.newsletter .block-container > button, .page-footer .block.newsletter .footer.content .block-container > button {
      position: absolute;
      top: 0;
      right: 0; } }

.wrapper.container h2, .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .container.submenu-inner h2, .sections.nav-sections .ves-megamenu .navigation .page-footer .nav-item.subhover > .submenu > .submenu-inner.footer.content h2, .page-footer .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .submenu-inner.footer.content h2, .page-footer .footer.content h2 {
  display: inline-block;
  margin: 0 0 16px; }
  @media only screen and (min-width: 768px) and (max-width: 1439px) {
    .wrapper.container h2.inline, .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .container.submenu-inner h2.inline, .sections.nav-sections .ves-megamenu .navigation .page-footer .nav-item.subhover > .submenu > .submenu-inner.footer.content h2.inline, .page-footer .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .submenu-inner.footer.content h2.inline, .page-footer .footer.content h2.inline {
      margin: 7px 0 31px; } }
  @media only screen and (min-width: 1440px) {
    .wrapper.container h2.inline, .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .container.submenu-inner h2.inline, .sections.nav-sections .ves-megamenu .navigation .page-footer .nav-item.subhover > .submenu > .submenu-inner.footer.content h2.inline, .page-footer .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .submenu-inner.footer.content h2.inline, .page-footer .footer.content h2.inline {
      margin: 4px 0 36px; } }

.breadcrumbs {
  margin-top: 24px; }
  @media only screen and (max-width: 1023px) {
    .breadcrumbs {
      display: none; } }

.box--classic, .post-list .post-content {
  border: 1px solid #DDDDDD; }
  @media only screen and (max-width: 767px) {
    .box--classic, .post-list .post-content {
      padding: 18px;
      margin-bottom: 12px; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .box--classic, .post-list .post-content {
      padding: 18px 24px; } }
  @media only screen and (min-width: 768px) {
    .box--classic, .post-list .post-content {
      margin-bottom: 24px; } }
  @media only screen and (min-width: 1024px) {
    .box--classic, .post-list .post-content {
      padding: 24px 32px; } }
  .box--classic h5, .post-list .post-content h5 {
    color: #1F2B5A;
    font-weight: 700; }
  .box--classic p:last-child, .post-list .post-content p:last-child {
    margin: 0; }

.box--center, .product-info-main .product-info-main-content {
  border: 1px solid #DDDDDD; }
  @media only screen and (max-width: 767px) {
    .box--center, .product-info-main .product-info-main-content {
      padding: 18px;
      margin-bottom: 16px; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .box--center, .product-info-main .product-info-main-content {
      padding: 24px;
      margin-bottom: 18px; } }
  @media only screen and (min-width: 1024px) {
    .box--center, .product-info-main .product-info-main-content {
      padding: 32px;
      margin-bottom: 24px; } }

.btn, .page-footer .block.newsletter button {
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  font-weight: 700;
  padding: 9px 16px; }
  @media only screen and (max-width: 767px) {
    .btn, .page-footer .block.newsletter button {
      width: 100%; } }
  .btn--primary {
    background: #1F2B5A;
    box-shadow: 0 4px 12px 0 rgba(56, 54, 127, 0.2);
    border: none;
    color: #FFFFFF !important;
    text-decoration: none; }
    .btn--primary:hover, .btn--primary:focus, .btn--primary:active {
      background: #1F2B5A;
      box-shadow: 0 4px 12px 0 rgba(56, 54, 127, 0.2);
      border: none;
      color: #FFFFFF !important;
      text-decoration: none; }
  .btn--primary_revert {
    color: #1F2B5A !important;
    background-color: #EFF7FC; }
  .btn--secondary, .page-footer .block.newsletter button {
    background: #FF8F00;
    box-shadow: 0 4px 12px 0 rgba(255, 143, 0, 0.2);
    border: none;
    color: #FFFFFF !important;
    text-decoration: none; }
    .btn--secondary:hover, .page-footer .block.newsletter button:hover, .btn--secondary:focus, .page-footer .block.newsletter button:focus, .btn--secondary:active, .page-footer .block.newsletter button:active {
      background: #FF8F00;
      box-shadow: 0 4px 12px 0 rgba(255, 143, 0, 0.2);
      border: none;
      color: #FFFFFF !important;
      text-decoration: none; }
  .btn--tertiary {
    background: #36D08C;
    box-shadow: 0 4px 12px 0 rgba(44, 209, 138, 0.2);
    border: none;
    color: #FFFFFF !important;
    text-decoration: none; }
    .btn--tertiary:hover, .btn--tertiary:focus, .btn--tertiary:active {
      background: #36D08C;
      box-shadow: 0 4px 12px 0 rgba(44, 209, 138, 0.2);
      border: none;
      color: #FFFFFF !important;
      text-decoration: none; }
  .btn--default {
    background: #393B40;
    box-shadow: 0 4px 12px 0 rgba(47, 47, 47, 0.2);
    border: none;
    color: #FFFFFF !important;
    text-decoration: none; }
    .btn--default:hover, .btn--default:focus, .btn--default:active {
      background: #393B40;
      box-shadow: 0 4px 12px 0 rgba(47, 47, 47, 0.2);
      border: none;
      color: #FFFFFF !important;
      text-decoration: none; }
  .btn--scroll {
    display: block;
    width: 26px;
    height: 48px;
    background-image: url("../images/icons/icon-scroll.svg");
    background-color: transparent;
    background-repeat: no-repeat; }
  @media only screen and (min-width: 768px) {
    .btn--corner {
      position: absolute;
      top: 0;
      right: 0; } }

.cms-page-banner {
  display: inline-block;
  width: 100%; }
  @media only screen and (min-width: 1440px) {
    .cms-page-banner {
      margin-bottom: 32px; } }
  .cms-page-banner img {
    width: 100%; }

@media only screen and (max-width: 767px) {
  ul.nav.items {
    margin-left: -16px;
    margin-right: -16px; } }

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  ul.nav.items {
    margin-left: -32px;
    margin-right: -32px; } }

@media only screen and (max-width: 1023px) {
  ul.nav.items {
    position: relative;
    padding-top: 42px; } }
  @media only screen and (max-width: 1023px) and (min-width: 768px) and (max-width: 1023px) {
    ul.nav.items .nav.item {
      padding-left: 16px;
      padding-right: 16px; } }

@media only screen and (max-width: 1023px) {
    ul.nav.items .nav.item.current {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      cursor: pointer;
      background-color: #FFFFFF;
      border-top: 1px solid #DDDDDD;
      border-bottom: 1px solid #DDDDDD; }
      ul.nav.items .nav.item.current strong {
        position: relative;
        color: #393B40; }
        ul.nav.items .nav.item.current strong:after {
          content: url(../images/icons/icon-down-dark.svg);
          cursor: pointer;
          position: absolute;
          top: 0;
          right: 0;
          padding: 12px 16px; }
    ul.nav.items .nav.item:not(.current) {
      display: none; }
    ul.nav.items.sidebar-visible .nav.item {
      display: block; }
      ul.nav.items.sidebar-visible .nav.item.current strong:after {
        transform: rotate(180deg); } }

.toolbar-products .pages-items .item {
  margin: 0;
  width: 40px;
  text-align: center;
  border: 1px solid #DDDDDD;
  background-color: #FFFFFF; }
  .toolbar-products .pages-items .item.current {
    background-color: #F9F9F9; }
    .toolbar-products .pages-items .item.current .page {
      color: #393B40; }
  .toolbar-products .pages-items .item a.page {
    color: #7F7F7F; }
  .toolbar-products .pages-items .item:not(:last-child) {
    border-right: 0; }
  @media only screen and (max-width: 1023px) {
    .toolbar-products .pages-items .item:not(.pages-item-previous):not(.current):not(.pages-item-next) {
      display: none; } }
  .toolbar-products .pages-items .item .page,
  .toolbar-products .pages-items .item .action {
    padding: 12px;
    line-height: 14px;
    font-size: 14px; }
  .toolbar-products .pages-items .item .action {
    margin: 0;
    border: 0; }
    .toolbar-products .pages-items .item .action:before {
      margin-left: -15px;
      font-size: 40px; }

@media only screen and (max-width: 1023px) {
  .sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .submenu-inner {
    padding-left: 0;
    padding-right: 0; } }

.sections.nav-sections .ves-megamenu .navigation .nav-item.subhover > .submenu > .submenu-inner img {
  display: block;
  margin: 0 auto; }

@media only screen and (max-width: 767px) {
  .sections.nav-sections .ves-megamenu .navigation .nav-item a {
    padding: 0 16px; } }

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .sections.nav-sections .ves-megamenu .navigation .nav-item a {
    padding: 0 36px; } }

@media only screen and (max-width: 1023px) {
  .sections.nav-sections .ves-megamenu .navigation .nav-item a {
    line-height: 40px; } }

@media only screen and (min-width: 1024px) {
  .sections.nav-sections .ves-megamenu .navigation .nav-item a {
    line-height: 48px; } }

@media only screen and (min-width: 1024px) {
  .sections.nav-sections .ves-megamenu .navigation .level0.current > a {
    position: relative; }
    .sections.nav-sections .ves-megamenu .navigation .level0.current > a:before {
      content: '';
      position: absolute;
      bottom: 0;
      height: 2px;
      background-color: #FF8F00;
      width: 100%; } }

@media only screen and (min-width: 1024px) {
  .sections.nav-sections .ves-megamenu .navigation .level0 .submenu .submenu-inner .item-footer {
    margin-bottom: 32px; }
    .sections.nav-sections .ves-megamenu .navigation .level0 .submenu .submenu-inner .item-footer a {
      line-height: inherit;
      display: inline-block; }
      .sections.nav-sections .ves-megamenu .navigation .level0 .submenu .submenu-inner .item-footer a:hover {
        background-color: #FF8F00; } }

.sections.nav-sections .ves-megamenu .navigation .level0 .submenu .active > a {
  color: #FFFFFF;
  border: none;
  background-color: #2D2F33; }

.sections.nav-sections .ves-megamenu .navigation .item-sidebar.right-sidebar {
  float: right; }

@media only screen and (max-width: 767px) {
  .sections.nav-sections .ves-megamenu.ves-offcanvas .opener {
    margin-right: 10px; } }

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .sections.nav-sections .ves-megamenu.ves-offcanvas .opener {
    margin-right: 30px; } }

@media only screen and (min-width: 1024px) {
  .sections.nav-sections .ves-megamenu.ves-offcanvas .level0 .submenu .nav-item > a, .sections.nav-sections .ves-megamenu.ves-dhorizontal .level0 .submenu .nav-item > a {
    line-height: 23px;
    font-size: 18px;
    padding: 0;
    margin-bottom: 16px; } }

@media only screen and (min-width: 1024px) {
  .sections.nav-sections .ves-megamenu.ves-offcanvas .level2 .mega-col .nav-item > a, .sections.nav-sections .ves-megamenu.ves-dhorizontal .level2 .mega-col .nav-item > a {
    line-height: 16px;
    font-size: 14px;
    margin-bottom: 8px;
    color: #BEBFC1; } }

@media only screen and (min-width: 1024px) {
  .sections.nav-sections .home-account-link {
    display: none !important; } }

@media only screen and (min-width: 1024px) {
  .ves-megamenu .navigation .mega-col .child-network-hardware:nth-child(1) {
    min-height: 159px; }
  .ves-megamenu .navigation .mega-col .child-network-hardware:nth-child(2) {
    min-height: 97px; }
  .ves-megamenu .navigation .mega-col .child-network-hardware:nth-child(3) {
    min-height: auto; }
  .ves-megamenu .navigation .mega-col .child-default-category {
    min-height: 97px; }
  .ves-megamenu .navigation .mega-col .child-network-accessories {
    min-height: 56px; } }

.cms-page-view .column.main {
  order: 2; }

.cms-page-view .columns .sidebar {
  order: 1; }

@media only screen and (min-width: 1024px) {
  .cms-page-view .column.main {
    width: 75%; }
  .cms-page-view .columns .sidebar {
    width: 25%; } }

.cms-page-view .page-title {
  margin-bottom: 32px; }

.cms-page-view h2 {
  color: #1F2B5A;
  margin-top: 0; }

.cms-page-view .sub-title {
  font-size: 16px;
  color: #333; }

.cms-page-view .cms-navigation {
  padding: 0;
  list-style: none;
  width: 100%; }
  .cms-page-view .cms-navigation li {
    background: #F9F9F9;
    margin-bottom: 1px; }
    .cms-page-view .cms-navigation li a {
      text-decoration: none;
      padding: 10px;
      display: block;
      width: 100%; }
    .cms-page-view .cms-navigation li.active {
      background: #1F2B5A; }
      .cms-page-view .cms-navigation li.active a {
        color: #fff; }
    .cms-page-view .cms-navigation li:not(.active):hover {
      background: #e7eaf7; }

.cms-home #maincontent {
  display: none; }

@media only screen and (min-width: 1440px) {
  .cms-about-us .box-container .box-column {
    width: calc((100% / 3) - (24px * 2 / 3));
    float: left; }
    .cms-about-us .box-container .box-column:not(:last-child) {
      margin-right: 24px; } }

@media only screen and (min-width: 768px) and (max-width: 1439px) {
  .cms-about-us .box--classic, .cms-about-us .post-list .post-content, .post-list .cms-about-us .post-content {
    width: calc(50% - (24px / 2)); }
    .cms-about-us .box--classic:nth-child(odd), .cms-about-us .post-list .post-content:nth-child(odd), .post-list .cms-about-us .post-content:nth-child(odd) {
      float: left;
      margin-right: 24px; }
    .cms-about-us .box--classic:nth-child(even), .cms-about-us .post-list .post-content:nth-child(even), .post-list .cms-about-us .post-content:nth-child(even) {
      float: right; } }

.customer-account-login .block.block-customer-login {
  background-color: #F9F9F9; }
  .customer-account-login .block.block-customer-login .actions-toolbar {
    position: relative; }
    .customer-account-login .block.block-customer-login .actions-toolbar .primary {
      display: inline-block;
      margin: 0; }
    .customer-account-login .block.block-customer-login .actions-toolbar .secondary {
      position: absolute;
      bottom: 0;
      right: 0; }
    .customer-account-login .block.block-customer-login .actions-toolbar .action.remind {
      font-size: 12px;
      line-height: 14px;
      text-decoration: underline;
      color: #393B40; }

@media only screen and (max-width: 767px) {
  .catalog-product-view .column.main {
    margin: 16px 0 24px; } }

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .catalog-product-view .column.main {
    margin: 32px 0 24px; } }

@media only screen and (min-width: 1024px) {
  .catalog-product-view .column.main {
    margin-top: 32px; } }

@media only screen and (max-width: 767px) {
  .product.media {
    margin-bottom: 16px; } }

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .product.media {
    margin-bottom: 32px; } }

@media only screen and (min-width: 1024px) {
  .product.media {
    margin-bottom: 64px; } }

.product.media .fotorama__stage {
  border: 1px solid #DDDDDD; }
  @media only screen and (min-width: 768px) {
    .product.media .fotorama__stage {
      left: 0 !important; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .product.media .fotorama__stage {
      width: 100% !important; } }
  @media only screen and (min-width: 1024px) {
    .product.media .fotorama__stage {
      width: calc(100% - 96px) !important; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .product.media .fotorama__stage .fotorama__stage__shaft {
      max-width: 100% !important; } }

.product.media .fotorama__nav-wrap {
  right: 0;
  top: -2px;
  left: auto; }
  @media only screen and (max-width: 1023px) {
    .product.media .fotorama__nav-wrap {
      display: none !important; } }
  .product.media .fotorama__nav-wrap .fotorama__nav--thumbs {
    height: auto !important; }
    .product.media .fotorama__nav-wrap .fotorama__nav--thumbs .fotorama__thumb {
      border: 1px solid #DDDDDD; }

.product-info-main .product-info-main-content {
  margin-bottom: 0; }
  @media only screen and (max-width: 1023px) {
    .product-info-main .product-info-main-content .page-title {
      margin: 0 0 12px; } }
  @media only screen and (min-width: 1024px) {
    .product-info-main .product-info-main-content .page-title {
      margin: 0 0 16px; } }
  .product-info-main .product-info-main-content .product.attribute.overview {
    font-size: 12px;
    border-bottom: 1px solid #DDDDDD; }
    @media only screen and (max-width: 767px) {
      .product-info-main .product-info-main-content .product.attribute.overview {
        padding-bottom: 16px;
        margin-bottom: 16px; } }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .product-info-main .product-info-main-content .product.attribute.overview {
        padding-bottom: 18px;
        margin-bottom: 18px; } }
    @media only screen and (min-width: 1024px) {
      .product-info-main .product-info-main-content .product.attribute.overview {
        padding-bottom: 24px;
        margin-bottom: 24px; } }
    .product-info-main .product-info-main-content .product.attribute.overview p {
      margin: 0; }
  .product-info-main .product-info-main-content .price-wrapper:before {
    content: 'Incl. VAT:';
    margin-right: 8px; }
  .product-info-main .product-info-main-content .price-including-tax:before {
    content: attr(data-label) ": ";
    font-size: 12px;
    margin-right: 8px; }
  .product-info-main .product-info-main-content .price {
    color: #1F2B5A; }

.product-info-main .product-add-form {
  border: 1px solid #DDDDDD;
  border-top: none;
  background-color: #F9F9F9; }
  @media only screen and (max-width: 767px) {
    .product-info-main .product-add-form {
      padding: 14px 18px;
      margin-bottom: 16px; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .product-info-main .product-add-form {
      padding: 18px 24px;
      margin-bottom: 32px; } }
  @media only screen and (min-width: 1024px) {
    .product-info-main .product-add-form {
      padding: 24px 32px;
      margin-bottom: 64px; } }
  .product-info-main .product-add-form .label {
    display: none !important; }
  .product-info-main .product-add-form .field.qty {
    display: inline-block; }
    @media only screen and (max-width: 1023px) {
      .product-info-main .product-add-form .field.qty {
        margin: 0 14px 0 0; } }
    @media only screen and (min-width: 1024px) {
      .product-info-main .product-add-form .field.qty {
        margin: 0 16px 0 0; } }
  .product-info-main .product-add-form .actions {
    display: inline-block; }
    @media only screen and (max-width: 1023px) {
      .product-info-main .product-add-form .actions {
        width: calc(100% - 74px); } }
    .product-info-main .product-add-form .actions .btn, .product-info-main .product-add-form .actions .page-footer .block.newsletter button, .page-footer .block.newsletter .product-info-main .product-add-form .actions button {
      width: 100%;
      padding: 11px 32px; }

@media only screen and (min-width: 1024px) {
  .product.info.detailed {
    width: calc(66.666% - 12px);
    float: left;
    clear: left; } }

@media only screen and (min-width: 1024px) {
  .sales-block {
    width: calc(33.333% - 12px);
    float: right;
    clear: right; } }

.product-page-tabs h3 {
  color: #393B40;
  margin-bottom: 14px; }

.product-page-tabs .value p:last-child {
  margin-bottom: 0; }

.checkout-onepage-success .column.main {
  border: 1px solid #DDDDDD;
  background-color: #F9F9F9; }
  @media only screen and (max-width: 1023px) {
    .checkout-onepage-success .column.main {
      padding: 16px;
      margin: 16px 0 24px; } }
  @media only screen and (min-width: 1024px) {
    .checkout-onepage-success .column.main {
      padding: 32px;
      margin: 32px 0 64px; } }
  .checkout-onepage-success .column.main a {
    color: inherit; }
  .checkout-onepage-success .column.main .actions-toolbar .primary {
    text-align: left; }
  .checkout-onepage-success .column.main .action.btn, .checkout-onepage-success .column.main .page-footer .block.newsletter button.action, .page-footer .block.newsletter .checkout-onepage-success .column.main button.action {
    width: auto !important; }

.checkout-onepage-success #registration {
  border-bottom: 1px solid #DDDDDD; }
  @media only screen and (max-width: 1023px) {
    .checkout-onepage-success #registration {
      padding-bottom: 16px;
      margin-bottom: 16px; } }
  @media only screen and (min-width: 1024px) {
    .checkout-onepage-success #registration {
      padding-bottom: 32px;
      margin-bottom: 32px; } }
  .checkout-onepage-success #registration .title {
    font-size: 18px;
    font-weight: 700; }

.checkout-onepage-success .checkout-success .title {
  font-size: 24px;
  line-height: 24px;
  font-weight: 700; }
  @media only screen and (max-width: 767px) {
    .checkout-onepage-success .checkout-success .title {
      margin-bottom: 12px; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .checkout-onepage-success .checkout-success .title {
      margin-bottom: 14px; } }
  @media only screen and (min-width: 1024px) {
    .checkout-onepage-success .checkout-success .title {
      margin-bottom: 16px; } }

.post-list {
  padding: 0; }
  @media only screen and (min-width: 768px) {
    .post-list {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-flow: row wrap;
      flex-flow: row wrap; } }
  .post-list .post-holder {
    border: 1px solid #DDDDDD;
    margin-bottom: 24px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap; }
    @media only screen and (min-width: 768px) {
      .post-list .post-holder {
        -webkit-flex-basis: calc(50% - 12px);
        flex-basis: calc(50% - 12px);
        margin-right: 24px; }
        .post-list .post-holder:nth-child(2n) {
          margin-right: 0; } }
  .post-list .post-header {
    margin-left: -1px;
    margin-right: -1px;
    margin-top: -1px; }
  .post-list .post-ftimg-hld a {
    display: block;
    position: relative;
    overflow: hidden; }
    @media only screen and (max-width: 767px) {
      .post-list .post-ftimg-hld a {
        height: 130px; } }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .post-list .post-ftimg-hld a {
        height: 152px; } }
    @media only screen and (min-width: 1024px) {
      .post-list .post-ftimg-hld a {
        height: 164px; } }
  .post-list .post-ftimg-hld img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%; }
  .post-list .post-content {
    border: none;
    margin: 0;
    -webkit-box-flex: 1;
    flex: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap; }
    .post-list .post-content .post-title a {
      color: inherit;
      font-weight: 700;
      text-decoration: none; }
    .post-list .post-content .post-text-hld {
      margin-bottom: 12px; }
    .post-list .post-content .post-info {
      margin-top: auto;
      margin-bottom: 16px; }
      .post-list .post-content .post-info .label,
      .post-list .post-content .post-info .dash,
      .post-list .post-content .post-info .item.post-categories {
        display: none; }
    .post-list .post-content .post-read-more {
      width: 100%;
      text-decoration: none; }

.widget.block-categories .block-title {
  margin-bottom: 14px; }
  @media only screen and (max-width: 1023px) {
    .widget.block-categories .block-title {
      display: none; } }

.widget.block-categories .mobile-switcher {
  position: relative;
  color: #393B40;
  font-weight: 700;
  border: 1px solid #DDDDDD;
  padding: 10px 16px; }
  .widget.block-categories .mobile-switcher:after {
    content: url(../images/icons/icon-down-dark.svg);
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 16px; }
  @media only screen and (min-width: 1024px) {
    .widget.block-categories .mobile-switcher {
      display: none; } }

@media only screen and (max-width: 1023px) {
  .widget.block-categories ul.accordion {
    display: none; } }

.widget.block-categories li {
  margin-bottom: 1px; }
  .widget.block-categories li a {
    background: #F9F9F9;
    color: #393B40;
    padding: 11px 16px;
    display: block;
    text-decoration: none; }

.widget.block-categories.sidebar-visible .mobile-switcher:after {
  transform: rotate(180deg); }

.widget.block-categories.sidebar-visible ul.accordion {
  display: block; }

.blog-category-view .category-content img {
  width: 100%; }

.blog-category-view .category-content .box--center, .blog-category-view .category-content .product-info-main .product-info-main-content, .product-info-main .blog-category-view .category-content .product-info-main-content {
  background: #F6F9FC;
  border: none; }

@media only screen and (min-width: 1024px) {
  .blog-category-view .post-list .post-holder {
    -webkit-flex-basis: calc(33.333% - 16px);
    flex-basis: calc(33.333% - 16px); }
    .blog-category-view .post-list .post-holder:nth-child(2n) {
      margin-right: 24px; }
    .blog-category-view .post-list .post-holder:nth-child(3n) {
      margin-right: 0; } }

.post-view .post-info .label,
.post-view .post-info .dash,
.post-view .post-info .item.post-categories {
  display: none; }

.post-view h1, .post-view h2, .post-view h3, .post-view h4, .post-view h5, .post-view h6 {
  color: #1F2B5A; }

@media only screen and (min-width: 1024px) {
  .post-view .post-holder {
    position: relative; } }

@media only screen and (max-width: 767px) {
  .post-view .post-holder .post-header {
    margin-bottom: 18px; } }

@media only screen and (min-width: 768px) {
  .post-view .post-holder .post-header {
    margin-bottom: 24px; } }

@media only screen and (min-width: 1024px) {
  .contact-index-index .column.main {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between; }
  .contact-index-index .contact-page-left {
    flex: 1;
    margin-right: 12px; }
  .contact-index-index .contact-page-right {
    flex: 1;
    margin-left: 12px; } }

.contact-index-index .block-content {
  line-height: 1.5em; }

.contact-index-index .column.main .block .block-content {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300; }

.contact-index-index .column.main .form.contact {
  background-color: #F9F9F9; }
  @media only screen and (max-width: 1023px) {
    .contact-index-index .column.main .form.contact {
      margin-bottom: 16px; } }
  .contact-index-index .column.main .form.contact .actions-toolbar {
    text-align: right; }
    .contact-index-index .column.main .form.contact .actions-toolbar .primary {
      float: none; }
      @media only screen and (max-width: 1023px) {
        .contact-index-index .column.main .form.contact .actions-toolbar .primary {
          display: block; } }
      .contact-index-index .column.main .form.contact .actions-toolbar .primary .btn, .contact-index-index .column.main .form.contact .actions-toolbar .primary .page-footer .block.newsletter button, .page-footer .block.newsletter .contact-index-index .column.main .form.contact .actions-toolbar .primary button {
        width: 100%; }

@media only screen and (max-width: 1023px) {
  .contact-index-index .column.main .iframe-container {
    height: 348px; } }

@media only screen and (min-width: 1024px) {
  .contact-index-index .column.main .iframe-container {
    height: 100%; } }

.block-accreditations {
  border-top: 1px solid #DDDDDD; }
  .block-accreditations h2 {
    color: #393B40;
    margin: 0 0 16px; }
  @media only screen and (max-width: 1023px) {
    .block-accreditations .accred-info {
      margin-bottom: 24px; } }
  @media only screen and (min-width: 1024px) {
    .block-accreditations .accred-info {
      margin-bottom: 32px; } }
  .block-accreditations .accred-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap; }
  .block-accreditations .accred-item {
    border: 1px solid #DDDDDD; }
    @media only screen and (max-width: 767px) {
      .block-accreditations .accred-item {
        padding: 8px;
        -webkit-flex-basis: calc(50% - 8px);
        flex-basis: calc(50% - 8px);
        margin-bottom: 12px;
        margin-right: 16px; }
        .block-accreditations .accred-item:nth-child(even) {
          margin-right: 0; }
        .block-accreditations .accred-item:last-child {
          margin-bottom: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .block-accreditations .accred-item {
        padding: 12px;
        -webkit-flex-basis: calc(33.333% - 16px);
        flex-basis: calc(33.333% - 16px);
        margin-bottom: 24px;
        margin-right: 24px; }
        .block-accreditations .accred-item:nth-child(3n+3) {
          margin-right: 0; }
        .block-accreditations .accred-item:last-child {
          margin-bottom: 0; } }
    @media only screen and (min-width: 1024px) {
      .block-accreditations .accred-item {
        padding: 32px;
        -webkit-flex-basis: calc(25% - 21px);
        flex-basis: calc(25% - 18px);
        margin-bottom: 24px;
        margin-right: 24px; }
        .block-accreditations .accred-item:nth-child(4n+4) {
          margin-right: 0; }
        .block-accreditations .accred-item:nth-child(n+5) {
          margin-bottom: 0; } }
    @media only screen and (max-width: 1023px) {
      .block-accreditations .accred-item h4 {
        margin: 0; } }
    .block-accreditations .accred-item p {
      margin: 0; }
      @media only screen and (max-width: 1023px) {
        .block-accreditations .accred-item p {
          display: none; } }
  .block-accreditations .accred-logo {
    font-size: 0;
    line-height: 0; }
    @media only screen and (max-width: 767px) {
      .block-accreditations .accred-logo img {
        max-height: 30px;
        margin-bottom: 8px; } }
    @media only screen and (min-width: 768px) {
      .block-accreditations .accred-logo img {
        max-height: 40px;
        margin-bottom: 12px; } }

.block-section {
  background: #393B40;
  color: #BEBFC1; }
  @media only screen and (min-width: 1024px) {
    .block-section {
      display: flex;
      align-items: center; } }
  .block-section h2 {
    color: #FFFFFF; }
  @media only screen and (min-width: 1024px) {
    .block-section .block-half {
      flex: 1; } }
  .block-section .section-content {
    box-sizing: border-box; }
    @media only screen and (max-width: 767px) {
      .block-section .section-content {
        padding: 24px 16px 16px; } }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .block-section .section-content {
        padding: 32px 36px; } }
    @media only screen and (min-width: 1024px) {
      .block-section .section-content {
        width: 558px;
        float: right; } }
    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
      .block-section .section-content {
        padding: 32px; } }
    @media only screen and (min-width: 1440px) {
      .block-section .section-content {
        padding: 42px 42px 42px 0; } }
  .block-section .section-img {
    position: relative;
    overflow: hidden;
    line-height: 0; }
    @media only screen and (max-width: 767px) {
      .block-section .section-img {
        height: 100px; } }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .block-section .section-img {
        height: 240px; } }
    @media only screen and (min-width: 1024px) {
      .block-section .section-img {
        height: 480px; } }
    .block-section .section-img img {
      width: 100%;
      min-height: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
      @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        .block-section .section-img img {
          height: 100%;
          width: auto;
          top: 0;
          left: 50%;
          transform: translateX(-50%); } }

.block-servers {
  border-bottom: 1px solid #DDDDDD; }
  @media only screen and (min-width: 1440px) {
    .block-servers .container, .block-servers .page-footer .footer.content, .page-footer .block-servers .footer.content {
      padding-top: 48px;
      padding-bottom: 48px; } }
  .block-servers h2 {
    color: #1F2B5A; }
  .block-servers .servers-content {
    position: relative; }
  .block-servers .servers-items {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between; }
  .block-servers .servers-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
    border: 1px solid #DDDDDD; }
    @media only screen and (max-width: 767px) {
      .block-servers .servers-item {
        padding: 8px;
        margin-bottom: 16px;
        -webkit-flex-basis: 48.5%;
        flex-basis: 48.5%; }
        .block-servers .servers-item:nth-child(n+3) {
          display: none; } }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .block-servers .servers-item {
        padding: 12px;
        -webkit-flex-basis: 31.8%;
        flex-basis: 31.8%; }
        .block-servers .servers-item:nth-child(n+4) {
          display: none; } }
    @media only screen and (max-width: 1023px) {
      .block-servers .servers-item {
        font-size: 10px; } }
    @media only screen and (min-width: 1024px) {
      .block-servers .servers-item {
        font-size: 12px;
        padding: 16px;
        -webkit-flex-basis: 23.5%;
        flex-basis: 23.5%; }
        .block-servers .servers-item:nth-child(n+5) {
          display: none; } }
    .block-servers .servers-item img {
      display: block;
      max-width: 100%;
      margin: 0 auto; }
    .block-servers .servers-item a {
      text-decoration: none; }
    .block-servers .servers-item h4 {
      color: #1F2B5A;
      z-index: 100; }
      .block-servers .servers-item h4:hover {
        overflow: visible;
        height: auto; }
    .block-servers .servers-item ul {
      padding-left: 14px; }
      .block-servers .servers-item ul li {
        margin: 0; }
    .block-servers .servers-item .item-price {
      color: #1F2B5A;
      font-size: 16px;
      font-weight: 700;
      margin-top: auto; }
      @media only screen and (max-width: 767px) {
        .block-servers .servers-item .item-price {
          margin-bottom: 8px; } }
      @media only screen and (min-width: 768px) and (max-width: 1023px) {
        .block-servers .servers-item .item-price {
          margin-bottom: 12px; } }
      @media only screen and (min-width: 1024px) {
        .block-servers .servers-item .item-price {
          margin-bottom: 16px; } }
    .block-servers .servers-item .btn, .block-servers .servers-item .page-footer .block.newsletter button, .page-footer .block.newsletter .block-servers .servers-item button {
      width: 100%;
      font-weight: 400; }

.block-featured-categories {
  background-color: #F6F9FC; }
  .block-featured-categories .categories-content {
    position: relative; }
    .block-featured-categories .categories-content h2 {
      color: #1F2B5A; }
  .block-featured-categories .categories-item {
    border: 1px solid #ECF1F5;
    background-color: #FFFFFF; }
    @media only screen and (max-width: 767px) {
      .block-featured-categories .categories-item {
        float: left;
        box-sizing: border-box;
        width: calc((100% / 2) - (16px - 16px / 2));
        margin-bottom: 16px; }
        .block-featured-categories .categories-item:not(:nth-child(2n+2)) {
          margin-right: 16px; } }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .block-featured-categories .categories-item {
        float: left;
        box-sizing: border-box;
        width: calc((100% / 3) - (24px - 24px / 3));
        margin-bottom: 24px; }
        .block-featured-categories .categories-item:not(:nth-child(3n+3)) {
          margin-right: 24px; } }
    @media only screen and (min-width: 1024px) {
      .block-featured-categories .categories-item {
        float: left;
        box-sizing: border-box;
        width: calc((100% / 6) - (24px - 24px / 6));
        margin-bottom: 24px; }
        .block-featured-categories .categories-item:not(:nth-child(6n+6)) {
          margin-right: 24px; } }
    .block-featured-categories .categories-item a {
      text-decoration: none; }
    .block-featured-categories .categories-item img {
      display: block;
      max-width: 100%;
      margin: 0 auto; }
    .block-featured-categories .categories-item h3 {
      color: #1F2B5A;
      margin: 0; }
      @media only screen and (max-width: 1023px) {
        .block-featured-categories .categories-item h3 {
          padding: 8px 12px; } }
      @media only screen and (min-width: 1024px) {
        .block-featured-categories .categories-item h3 {
          padding: 14px 16px; } }

.page-footer {
  color: #BEBFC1;
  background-color: #393B40; }
  .page-footer a {
    color: #BEBFC1; }
  .page-footer .footer.content {
    border: none;
    margin-top: 0; }
  .page-footer .footer-links {
    border-bottom: 1px solid #464950; }
    @media only screen and (max-width: 767px) {
      .page-footer .footer-links {
        margin-bottom: 24px; } }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .page-footer .footer-links {
        margin-bottom: 32px; } }
    @media only screen and (min-width: 1024px) {
      .page-footer .footer-links {
        margin-bottom: 48px; } }
    @media only screen and (max-width: 767px) {
      .page-footer .footer-links .links-box {
        margin-bottom: 24px; } }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .page-footer .footer-links .links-box {
        margin-bottom: 32px; } }
    @media only screen and (min-width: 1024px) {
      .page-footer .footer-links .links-box {
        margin-bottom: 48px;
        float: left;
        width: 50%; } }
    .page-footer .footer-links .links-box a {
      font-weight: 700;
      display: block;
      margin-bottom: 12px; }
    .page-footer .footer-links .links-box.categories a {
      text-decoration: none; }
      @media only screen and (min-width: 768px) {
        .page-footer .footer-links .links-box.categories a {
          float: left;
          width: 50%; } }
    .page-footer .footer-links .links-box.posts a {
      text-decoration: underline; }
  @media only screen and (max-width: 767px) {
    .page-footer .footer-contact {
      margin-bottom: 24px; } }
  @media only screen and (min-width: 768px) {
    .page-footer .footer-contact {
      float: left;
      width: 68%; } }
  .page-footer .footer-contact a {
    text-decoration: underline; }
  @media only screen and (min-width: 768px) {
    .page-footer .footer-contact .side {
      width: 50%;
      float: left;
      padding-right: 16px; } }
  .page-footer .footer-bottom {
    background-color: #2A2C30;
    padding: 14px 0; }
    @media only screen and (max-width: 767px) {
      .page-footer .footer-bottom {
        text-align: center; } }
  @media only screen and (min-width: 1024px) {
    .page-footer .block.newsletter > * {
      padding-left: 25%; } }
  .page-footer .block.newsletter .title strong {
    font-weight: 400; }
  .page-footer .block.newsletter .field {
    margin-bottom: 8px; }
    @media only screen and (min-width: 1024px) {
      .page-footer .block.newsletter .field {
        float: left;
        width: calc(100% - 67px); } }
  @media only screen and (min-width: 1024px) {
    .page-footer .block.newsletter .actions {
      float: right; } }
  .page-footer .block.newsletter input {
    height: 36px; }
  @media only screen and (max-width: 1023px) {
    .page-footer .block.newsletter button {
      width: 100%; } }
  .page-footer .copyright {
    display: block;
    font-size: 14px; }
    @media only screen and (max-width: 767px) {
      .page-footer .copyright {
        margin-bottom: 16px; } }
    @media only screen and (min-width: 768px) {
      .page-footer .copyright {
        width: 50%;
        float: left;
        padding-right: 16px; } }
  @media only screen and (min-width: 768px) {
    .page-footer .credit-cards {
      width: 50%;
      float: left;
      text-align: right; } }
  .page-footer .footer_cms_links {
    float: left;
    width: 100%;
    margin-top: 20px; }

.page-header {
  border: none;
  margin: 0; }
  @media only screen and (min-width: 1024px) {
    .page-header .panel.content {
      border-bottom: 1px solid #DDDDDD; } }
  .page-header .panel.header .header.links {
    color: #393B40; }
    @media only screen and (min-width: 1024px) {
      .page-header .panel.header .header.links {
        display: flex;
        width: 100%;
        align-items: center; } }
    .page-header .panel.header .header.links li {
      order: 5;
      border-left: 1px solid #DDDDDD;
      padding: 0 16px;
      margin: 0; }
      .page-header .panel.header .header.links li.greet.welcome {
        font-weight: 700;
        order: 1;
        border-left: none;
        padding-left: 0; }
      .page-header .panel.header .header.links li.phone {
        order: 2;
        margin-right: auto; }
      .page-header .panel.header .header.links li.language-change {
        order: 3;
        border-left: none; }
        .page-header .panel.header .header.links li.language-change .action.toggle:after {
          content: ''; }
      .page-header .panel.header .header.links li:nth-child(2) {
        padding-right: 0; }
    .page-header .panel.header .header.links a {
      color: #393B40;
      text-decoration: none; }
  @media only screen and (max-width: 767px) {
    .page-header .header.content {
      padding-right: 16px;
      padding-left: 16px; } }
  @media only screen and (min-width: 768px) and (max-width: 1439px) {
    .page-header .header.content {
      padding-right: 36px;
      padding-left: 36px;
      max-width: calc(1116px + (36px * 2));
      margin: 0 auto; } }
  @media only screen and (max-width: 1439px) {
    .page-header .header.content {
      padding-top: 8px;
      padding-bottom: 16px; } }
  @media only screen and (min-width: 1024px) {
    .page-header .header.content {
      padding-top: 24px;
      padding-bottom: 24px;
      display: flex;
      align-items: center; } }
  .page-header .header.content .logo {
    margin: 0; }
    @media only screen and (min-width: 1024px) {
      .page-header .header.content .logo {
        flex: 1;
        order: 1; } }
    @media only screen and (max-width: 1023px) {
      .page-header .header.content .logo img {
        max-width: 109px; } }
  .page-header .header.content .phone-mobile {
    cursor: pointer;
    position: absolute;
    top: 19px; }
    @media only screen and (max-width: 767px) {
      .page-header .header.content .phone-mobile {
        right: 145px; }
        .page-header .header.content .phone-mobile.translatex {
          right: 182px; } }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .page-header .header.content .phone-mobile {
        right: 165px; }
        .page-header .header.content .phone-mobile.translatex {
          right: 202px; } }
    @media only screen and (min-width: 1024px) {
      .page-header .header.content .phone-mobile {
        display: none; } }
    .page-header .header.content .phone-mobile span:before {
      content: url(../images/icons/icon-phone.svg);
      width: 26px;
      height: 26px;
      font-size: 26px;
      line-height: 18px; }
  .page-header .header.content .block-search {
    margin: 0; }
    @media only screen and (min-width: 1024px) {
      .page-header .header.content .block-search {
        flex: 2;
        order: 2; } }
    .page-header .header.content .block-search .label {
      margin-right: 17px;
      margin-top: 8px; }
      .page-header .header.content .block-search .label:before {
        color: #393B40;
        width: 26px;
        height: 26px;
        font-size: 26px;
        line-height: 26px; }
    .page-header .header.content .block-search .control {
      border: none; }
    .page-header .header.content .block-search .action.search:before {
      content: url(../images/icons/icon-search.svg);
      border-radius: 6px;
      background-color: rgba(255, 143, 0, 0.1);
      font-size: 19px;
      line-height: 34px;
      padding: 0 14px; }
  @media only screen and (min-width: 1024px) {
    .page-header .header.content .minicart-container {
      flex: 1;
      order: 3;
      text-align: right; } }
  .page-header .header.content .minicart-wrapper {
    margin: 0; }
    @media only screen and (min-width: 1024px) {
      .page-header .header.content .minicart-wrapper {
        border: 1px solid #DDDDDD;
        border-radius: 4px; } }
    .page-header .header.content .minicart-wrapper .action.showcart {
      color: #393B40; }
      @media only screen and (max-width: 1023px) {
        .page-header .header.content .minicart-wrapper .action.showcart {
          margin-top: 8px;
          margin-right: 16px; } }
      @media only screen and (min-width: 1024px) {
        .page-header .header.content .minicart-wrapper .action.showcart {
          display: flex;
          align-items: center;
          padding: 6px 16px; } }
      .page-header .header.content .minicart-wrapper .action.showcart:before {
        content: url(../images/icons/icon-basket.svg);
        width: 26px;
        height: 26px;
        font-size: 26px;
        line-height: 18px; }
        @media only screen and (min-width: 1024px) {
          .page-header .header.content .minicart-wrapper .action.showcart:before {
            order: 1;
            margin: 0 4px; } }
      @media only screen and (max-width: 1023px) {
        .page-header .header.content .minicart-wrapper .action.showcart .text,
        .page-header .header.content .minicart-wrapper .action.showcart .price {
          display: none; } }
      .page-header .header.content .minicart-wrapper .action.showcart .text {
        width: auto;
        height: auto;
        position: static;
        font-weight: 700; }
      .page-header .header.content .minicart-wrapper .action.showcart .price {
        color: #7F7F7F; }
        @media only screen and (min-width: 1024px) {
          .page-header .header.content .minicart-wrapper .action.showcart .price {
            order: 2; } }
  .page-header .header.content .nav-toggle {
    float: right;
    position: static;
    margin-top: 8px; }
    .page-header .header.content .nav-toggle:before {
      color: #393B40;
      width: 26px;
      height: 26px;
      font-size: 26px;
      line-height: 26px; }

html.nav-open .page-header .header.content .nav-toggle {
  max-width: 26px;
  overflow: hidden; }
  html.nav-open .page-header .header.content .nav-toggle:before {
    margin-left: -5px;
    font-size: 36px;
    width: 36px; }

.our-services {
  border-top: 1px solid #DDDDDD;
  border-bottom: 1px solid #DDDDDD; }
  .our-services .service-container {
    text-align: center; }
    @media only screen and (min-width: 768px) {
      .our-services .service-container {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between; } }
    .our-services .service-container .service-item {
      position: relative;
      color: #1F2B5A;
      font-weight: 700;
      padding: 16px 0; }
      @media only screen and (min-width: 768px) {
        .our-services .service-container .service-item {
          flex: 1; }
          .our-services .service-container .service-item:first-child {
            text-align: left; } }
      @media only screen and (max-width: 767px) {
        .our-services .service-container .service-item:not(#i-qua) {
          display: none; } }
      @media only screen and (min-width: 768px) and (max-width: 1023px) {
        .our-services .service-container .service-item:nth-child(n+4) {
          display: none; }
        .our-services .service-container .service-item:nth-child(3) {
          text-align: right; } }
      @media only screen and (min-width: 1024px) {
        .our-services .service-container .service-item:last-child {
          text-align: right; } }
      @media only screen and (min-width: 1024px) and (max-width: 1439px) {
        .our-services .service-container .service-item#i-eco {
          display: none; } }
      @media only screen and (min-width: 1440px) {
        .our-services .service-container .service-item#i-eco-mobile {
          display: none; } }
      .our-services .service-container .service-item:before {
        vertical-align: middle;
        margin-right: 8px; }
      @media only screen and (min-width: 768px) {
        .our-services .service-container .service-item:not(:first-child):after {
          content: '';
          position: absolute;
          left: 0;
          top: 10px;
          height: 32px;
          width: 1px;
          background: #DDDDDD; } }
      .our-services .service-container .service-item#i-del:before {
        content: url(../images/icons/icon-delivery.svg); }
      .our-services .service-container .service-item#i-qua:before {
        content: url(../images/icons/icon-star.svg); }
      .our-services .service-container .service-item#i-eco:before, .our-services .service-container .service-item#i-eco-mobile:before {
        content: url(../images/icons/icon-ecosystem.svg); }
      .our-services .service-container .service-item#i-cert:before {
        content: url(../images/icons/icon-isocert.svg); }

.our-services-extend {
  border-bottom: 1px solid #DDDDDD; }
  .our-services-extend .box-holder {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between; }
  @media only screen and (max-width: 767px) {
    .our-services-extend .box {
      margin-bottom: 12px; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .our-services-extend .box {
      -webkit-flex-basis: 48.5%;
      flex-basis: 48.5%; } }
  @media only screen and (min-width: 1024px) {
    .our-services-extend .box {
      -webkit-flex-basis: 31.8%;
      flex-basis: 31.8%; } }
  @media only screen and (max-width: 1023px) {
    .our-services-extend .box:last-child {
      display: none; } }
  .our-services-extend .box--classic, .our-services-extend .post-list .post-content, .post-list .our-services-extend .post-content {
    border: none;
    background-color: #393B40;
    color: #BEBFC1;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0; }
  .our-services-extend h2 {
    color: #FFFFFF; }
    @media only screen and (max-width: 1023px) {
      .our-services-extend h2 {
        margin: 0 0 12px; } }
    @media only screen and (min-width: 1024px) {
      .our-services-extend h2 {
        margin: 0 0 16px; } }
  .our-services-extend figure {
    float: left;
    width: 100%; }
    @media only screen and (max-width: 1023px) {
      .our-services-extend figure {
        margin-bottom: 16px; } }
    @media only screen and (min-width: 1024px) {
      .our-services-extend figure {
        margin-bottom: 24px; } }
    .our-services-extend figure img {
      width: 100%; }

.block-promo {
  position: relative; }
  @media only screen and (max-width: 767px) {
    .block-promo {
      padding-top: 36px;
      padding-bottom: 36px; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .block-promo {
      padding-top: 76px;
      padding-bottom: 76px; } }
  @media only screen and (min-width: 1024px) {
    .block-promo {
      padding-top: 121px;
      padding-bottom: 121px; } }
  .block-promo.promo-main {
    background-color: #202C59;
    background-repeat: no-repeat;
    background-size: cover; }
    .block-promo.promo-main .box-promo {
      color: #FFFFFF; }
      .block-promo.promo-main .box-promo p {
        color: #DEDFE0; }
    .block-promo.promo-main .btn--scroll {
      position: absolute;
      bottom: 16px;
      left: 50%;
      transform: translateX(-50%);
      cursor: default; }
      @media only screen and (max-width: 1023px) {
        .block-promo.promo-main .btn--scroll {
          display: none; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .block-promo .box-promo {
      max-width: 48%; } }
  @media only screen and (min-width: 1024px) {
    .block-promo .box-promo {
      max-width: 35%; } }

.block-quote {
  border-top: 1px solid #DDDDDD; }
  @media only screen and (min-width: 1440px) {
    .block-quote {
      min-width: 1116px; } }
  .block-quote h2 {
    color: #393B40; }
  .block-quote .box--center, .block-quote .product-info-main .product-info-main-content, .product-info-main .block-quote .product-info-main-content {
    margin: 0; }
    .block-quote .box--center .collapse-head, .block-quote .product-info-main .product-info-main-content .collapse-head, .product-info-main .block-quote .product-info-main-content .collapse-head {
      position: relative;
      padding-right: 29px; }
      .block-quote .box--center .collapse-head:after, .block-quote .product-info-main .product-info-main-content .collapse-head:after, .product-info-main .block-quote .product-info-main-content .collapse-head:after {
        content: url(../images/icons/icon-down-dark.svg);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%); }
    .block-quote .box--center .header-p, .block-quote .product-info-main .product-info-main-content .header-p, .product-info-main .block-quote .product-info-main-content .header-p {
      margin-bottom: 0; }
    .block-quote .box--center.active .collapse-head:after, .block-quote .product-info-main .active.product-info-main-content .collapse-head:after, .product-info-main .block-quote .active.product-info-main-content .collapse-head:after {
      transform: translateY(-50%) rotate(180deg); }
    .block-quote .box--center.active .header-p, .block-quote .product-info-main .active.product-info-main-content .header-p, .product-info-main .block-quote .active.product-info-main-content .header-p {
      margin-bottom: 16px; }
  @media only screen and (min-width: 768px) {
    .block-quote form .field:not(.comment):not(.note) {
      width: calc(50% - 24px / 2); }
      .block-quote form .field:not(.comment):not(.note):nth-child(odd) {
        float: left;
        clear: left; }
      .block-quote form .field:not(.comment):not(.note):nth-child(even) {
        float: right;
        clear: right; } }
  @media only screen and (min-width: 768px) {
    .block-quote form .actions-toolbar .primary {
      float: right; } }

.block-reviews h2 {
  color: #1F2B5A; }

.block-reviews .reviews-content {
  position: relative; }

.block-reviews .review-item {
  border: 1px solid #DDDDDD; }
  @media only screen and (max-width: 767px) {
    .block-reviews .review-item {
      padding: 12px;
      float: left;
      box-sizing: border-box;
      width: calc((100% / 1) - (12px - 12px / 1));
      margin-bottom: 12px; }
      .block-reviews .review-item:not(:nth-child(1n+1)) {
        margin-right: 12px; }
      .block-reviews .review-item:nth-child(n+3) {
        display: none; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .block-reviews .review-item {
      padding: 12px;
      float: left;
      box-sizing: border-box;
      width: calc((100% / 3) - (24px - 24px / 3));
      margin-bottom: 24px; }
      .block-reviews .review-item:not(:nth-child(3n+3)) {
        margin-right: 24px; } }
  @media only screen and (min-width: 1024px) {
    .block-reviews .review-item {
      padding: 32px;
      float: left;
      box-sizing: border-box;
      width: calc((100% / 3) - (24px - 24px / 3));
      margin-bottom: 24px; }
      .block-reviews .review-item:not(:nth-child(3n+3)) {
        margin-right: 24px; } }
  @media only screen and (max-width: 1023px) {
    .block-reviews .review-item .stars {
      margin-bottom: 8px; } }
  @media only screen and (min-width: 1024px) {
    .block-reviews .review-item .stars {
      margin-bottom: 12px; } }
  .block-reviews .review-item .stars .star:before {
    content: url(../images/icons/icon-star.svg); }
  .block-reviews .review-item h3,
  .block-reviews .review-item .h3 {
    color: #1F2B5A; }
  .block-reviews .review-item .author {
    display: block;
    font-weight: 700; }
  .block-reviews .review-items {
    display: flex;
    flex-wrap: wrap; }

.section-about .about-item {
  border-bottom: 1px solid #464950;
  padding: 10px 0; }
  .section-about .about-item:first-child {
    border-top: 1px solid #464950; }
  .section-about .about-item h2 {
    position: relative;
    margin: 0; }
    .section-about .about-item h2:after {
      content: url(../images/icons/icon-down.svg);
      position: absolute;
      top: 0;
      right: 0;
      line-height: 22px; }
  .section-about .about-item.active h2 {
    margin-bottom: 16px; }
    .section-about .about-item.active h2:after {
      transform: rotate(180deg); }

.language-change .view-ukgbp a:before,
.language-change .view-ukgbp span:before {
  content: '';
  display: inline-block;
  background-image: url(../images/gb.png);
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  top: 2px;
  width: 19px;
  height: 14px;
  margin-right: 4px; }

.sales-block {
  background-color: #393B40; }
  .sales-block h2 {
    color: #FFFFFF; }
    @media only screen and (max-width: 1439px) {
      .sales-block h2 {
        margin: 0 0 12px; } }
    @media only screen and (min-width: 1440px) {
      .sales-block h2 {
        margin: 0 0 14px; } }
  .sales-block p {
    color: #BEBFC1; }
    .sales-block p:last-child {
      margin-bottom: 0; }
  @media only screen and (max-width: 767px) {
    .sales-block {
      padding: 18px; } }
  @media only screen and (min-width: 768px) and (max-width: 1439px) {
    .sales-block {
      padding: 24px; } }
  @media only screen and (min-width: 1440px) {
    .sales-block {
      padding: 24px 32px; } }

.block-suggested-products {
  border-top: 1px solid #DDDDDD; }
  .block-suggested-products h2 {
    color: #1F2B5A; }
  @media only screen and (max-width: 767px) {
    .block-suggested-products li.product-item {
      float: left;
      box-sizing: border-box;
      width: calc((100% / 2) - (16px - 16px / 2));
      margin-bottom: 16px; }
      .block-suggested-products li.product-item:not(:nth-child(2n+2)) {
        margin-right: 16px; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .block-suggested-products li.product-item {
      float: left;
      box-sizing: border-box;
      width: calc((100% / 3) - (24px - 24px / 3));
      margin-bottom: 24px; }
      .block-suggested-products li.product-item:not(:nth-child(3n+3)) {
        margin-right: 24px; } }
  @media only screen and (min-width: 1024px) {
    .block-suggested-products li.product-item {
      float: left;
      box-sizing: border-box;
      width: calc((100% / 4) - (24px - 24px / 4));
      margin-bottom: 24px; }
      .block-suggested-products li.product-item:not(:nth-child(4n+4)) {
        margin-right: 24px; } }

.review-form .review-control-vote:before,
.review-form .review-control-vote label:before {
  line-height: 16px; }

#product-reviews .rating-summary {
  margin-left: 16px;
  display: inline-block; }

#product-reviews .review-toolbar {
  border-top: none !important; }

#product-reviews .review-title {
  margin: 0px !important; }

#product-reviews .review-post .rating-summary {
  margin: 0; }

#product-reviews .review-post:not(:last-of-type) {
  border-bottom: 1px solid #DDDDDD; }
  @media only screen and (max-width: 767px) {
    #product-reviews .review-post:not(:last-of-type) {
      padding-bottom: 18px;
      margin-bottom: 18px; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    #product-reviews .review-post:not(:last-of-type) {
      padding-bottom: 24px;
      margin-bottom: 24px; } }
  @media only screen and (min-width: 1024px) {
    #product-reviews .review-post:not(:last-of-type) {
      padding-bottom: 32px;
      margin-bottom: 32px; } }

@media only screen and (max-width: 767px) {
  #product-reviews .review-stars {
    margin-bottom: 12px; } }

@media only screen and (min-width: 768px) {
  #product-reviews .review-stars {
    margin-bottom: 16px; } }

#product-reviews .review-stars span:before {
  content: url(../images/icons/icon-star.svg); }

#product-reviews .review-comment {
  clear: both; }
  @media only screen and (max-width: 767px) {
    #product-reviews .review-comment {
      margin-bottom: 12px; } }
  @media only screen and (min-width: 768px) {
    #product-reviews .review-comment {
      margin-bottom: 16px; } }

.product-page-tabs .box--center.product-reviews, .product-page-tabs .product-info-main .product-reviews.product-info-main-content, .product-info-main .product-page-tabs .product-reviews.product-info-main-content {
  position: relative; }
  @media only screen and (max-width: 767px) {
    .product-page-tabs .box--center.product-reviews h3, .product-page-tabs .product-info-main .product-reviews.product-info-main-content h3, .product-info-main .product-page-tabs .product-reviews.product-info-main-content h3 {
      margin-bottom: 40px; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .product-page-tabs .box--center.product-reviews h3, .product-page-tabs .product-info-main .product-reviews.product-info-main-content h3, .product-info-main .product-page-tabs .product-reviews.product-info-main-content h3 {
      margin-bottom: 24px; } }
  @media only screen and (min-width: 1024px) {
    .product-page-tabs .box--center.product-reviews h3, .product-page-tabs .product-info-main .product-reviews.product-info-main-content h3, .product-info-main .product-page-tabs .product-reviews.product-info-main-content h3 {
      margin-bottom: 26px; } }

.product-page-tabs .reviews-all {
  position: absolute; }
  @media only screen and (max-width: 767px) {
    .product-page-tabs .reviews-all {
      top: 18px;
      right: 18px; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .product-page-tabs .reviews-all {
      top: 24px;
      right: 24px; } }
  @media only screen and (min-width: 1024px) {
    .product-page-tabs .reviews-all {
      top: 32px;
      right: 32px; } }
  .product-page-tabs .reviews-all .product-reviews-summary {
    display: flex;
    align-items: center;
    margin: 0; }
    @media only screen and (max-width: 767px) {
      .product-page-tabs .reviews-all .product-reviews-summary {
        flex-direction: column;
        align-items: flex-end; }
        .product-page-tabs .reviews-all .product-reviews-summary .rating-summary {
          left: 5px;
          order: 1; }
          .product-page-tabs .reviews-all .product-reviews-summary .rating-summary-text {
            order: 2; } }
  .product-page-tabs .reviews-all .review-stars {
    direction: rtl;
    margin-left: 16px;
    margin-bottom: 8px; }
    @media only screen and (max-width: 767px) {
      .product-page-tabs .reviews-all .review-stars {
        display: block; } }
    @media only screen and (min-width: 768px) {
      .product-page-tabs .reviews-all .review-stars {
        display: inline-block; } }
  .product-page-tabs .reviews-all small {
    float: left; }

.review-list {
  border: 0 !important;
  padding: 0 !important; }
  .review-list .toolbar-number {
    display: none; }

.review-control-vote label:before {
  color: #FF8F00; }

.rating-summary .rating-result > span:before {
  color: #FF8F00; }

.block-special .box--center, .block-special .product-info-main .product-info-main-content, .product-info-main .block-special .product-info-main-content {
  background: #F6F9FC;
  border: none; }

.block-special h3 {
  color: #1F2B5A; }

.block-special p:last-child {
  margin: 0; }

.block-recently-viewed,
.block-suggested-products {
  border-top: 1px solid #DDDDDD; }
  .block-recently-viewed h2,
  .block-suggested-products h2 {
    color: #1F2B5A; }
  .block-recently-viewed .product-items,
  .block-suggested-products .product-items {
    display: flex;
    flex-wrap: wrap; }
    .block-recently-viewed .product-items .product-item,
    .block-suggested-products .product-items .product-item {
      width: 100%;
      margin-bottom: 24px; }
      @media only screen and (min-width: 768px) {
        .block-recently-viewed .product-items .product-item,
        .block-suggested-products .product-items .product-item {
          margin-right: 24px; } }
      @media only screen and (min-width: 768px) and (max-width: 1023px) {
        .block-recently-viewed .product-items .product-item,
        .block-suggested-products .product-items .product-item {
          width: calc(33.3% - 18px); }
          .block-recently-viewed .product-items .product-item:nth-child(3n+3),
          .block-suggested-products .product-items .product-item:nth-child(3n+3) {
            margin-right: 0; }
          .block-recently-viewed .product-items .product-item:nth-child(4n+4),
          .block-suggested-products .product-items .product-item:nth-child(4n+4) {
            display: none; } }
      @media only screen and (min-width: 1024px) {
        .block-recently-viewed .product-items .product-item,
        .block-suggested-products .product-items .product-item {
          width: calc(25% - 18px); }
          .block-recently-viewed .product-items .product-item:nth-child(4n+4),
          .block-suggested-products .product-items .product-item:nth-child(4n+4) {
            margin-right: 0; } }
      .block-recently-viewed .product-items .product-item-image,
      .block-suggested-products .product-items .product-item-image {
        text-align: center; }
      .block-recently-viewed .product-items .product-item-info,
      .block-suggested-products .product-items .product-item-info {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between; }
      .block-recently-viewed .product-items .product-item-name,
      .block-suggested-products .product-items .product-item-name {
        min-height: 36px; }
