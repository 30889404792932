.block-featured-categories {
  background-color: $c-highlight;

  .categories-content {
    position: relative;

    h2 {
      color: $c-blue;
    }
  }

  .categories-item {
    border: 1px solid #ECF1F5;
    background-color: $c-white;

    @include max-screen($bp-tablet) {
      @include float-item(2,$trim);
    }

    @include screen($bp-tablet,$bp-desktop-s) {
      @include float-item(3,$trim--l);
    }

    @include min-screen($bp-desktop-s) {
      @include float-item(6,$trim--l);
    }

    a {
      text-decoration: none;
    }

    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }

    h3 {
      color: $c-blue;
      margin: 0;

      @include max-screen($bp-desktop-s) {
        padding: $trim--xs $trim--s;
      }
      @include min-screen($bp-desktop-s) {
        padding: 14px $trim;
      }
    }
  }
}