$c-text             : #505154;
$c-text--light      : #DEDFE0;
$c-text--reverse    : #BEBFC1;

$c-white            : #FFFFFF;
$c-black            : #2A2C30;

$c-dark             : #393B40;
$c-blue             : #1F2B5A;
$c-orange           : #FF8F00;
$c-orange--light    : rgba(255, 143, 0, 0.1);
$c-green            : #36D08C;
$c-green--light     : #EFFCF0;
$c-pink             : #F9F9F9;
$c-highlight        : #F6F9FC;

$c-border           : #DDDDDD;
$c-border--light    : #ECECEC;
$c-border--reverse  : #464950;

$c-opacity          : #7F7F7F;

$c-nav--bg          : #2D2F33;