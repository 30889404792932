.catalog-product-view {
  .column.main {
    @include max-screen($bp-tablet) {
      margin: $trim 0 $trim--l;
    }

    @include screen($bp-tablet,$bp-desktop-s) {
      margin: $trim--xl 0 $trim--l;
    }

    @include min-screen($bp-desktop-s) {
      margin-top: $trim--xl;
    }

  }
}

//  Product image(s)
.product.media {

  @include max-screen($bp-tablet) {
    margin-bottom: $trim;
  }

  @include screen($bp-tablet,$bp-desktop-s) {
    margin-bottom: $trim--xl;
  }

  @include min-screen($bp-desktop-s) {
    margin-bottom: $trim--xl * 2;
  }

  .fotorama__stage {
    border: 1px solid $c-border;

    @include min-screen($bp-tablet) {
      left: 0 !important;
    }

    @include screen($bp-tablet,$bp-desktop-s) {
      width: 100% !important;
    }

    @include min-screen($bp-desktop-s) {
      width: calc(100% - 96px) !important;
    }

    .fotorama__stage__shaft {
      @include screen($bp-tablet,$bp-desktop-s) {
        max-width: 100% !important;
      }
    }
  }

  .fotorama__nav-wrap {
    right: 0;
    top: -2px;
    left: auto;

    @include max-screen($bp-desktop-s) {
      display: none !important;
    }

    .fotorama__nav--thumbs {
      height: auto !important;

      .fotorama__thumb {
        border: 1px solid $c-border;
      }
    }
  }
}

// Product main info
.product-info-main {
  .product-info-main-content {

    @extend .box--center;
    margin-bottom: 0;

    .page-title {
      @include max-screen($bp-desktop-s) {
        margin: 0 0 $trim--s;
      }

      @include min-screen($bp-desktop-s) {
        margin: 0 0 $trim;
      }
    }

    .product.attribute.overview {
      font-size: $f-small;
      border-bottom: 1px solid $c-border;

      @include max-screen($bp-tablet) {
        padding-bottom: $trim;
        margin-bottom: $trim;
      }

      @include screen($bp-tablet,$bp-desktop-s) {
        padding-bottom: 18px;
        margin-bottom: 18px;
      }

      @include min-screen($bp-desktop-s) {
        padding-bottom: $trim--l;
        margin-bottom: $trim--l;
      }

      p {
        margin: 0;
      }
    }

    .price-wrapper {
      &:before {
        content: 'Incl. VAT:';
        margin-right: $trim--xs;
      }
    }

    .price-including-tax {

      &:before {
        content: attr(data-label) ': ';
        font-size: $f-small;
        margin-right: $trim--xs;
      }
    }

    .price {
      color: $c-blue;
    }
  }

  .product-add-form {
    border: 1px solid $c-border;
    border-top: none;
    background-color: $c-pink;

    @include max-screen($bp-tablet) {
      padding: 14px 18px;
      margin-bottom: $trim;
    }

    @include screen($bp-tablet,$bp-desktop-s) {
      padding: 18px $trim--l;
      margin-bottom: $trim--xl;
    }

    @include min-screen($bp-desktop-s) {
      padding: $trim--l $trim--xl;
      margin-bottom: $trim--xl * 2;
    }
    
    .label {
      display: none !important;
    }

    .field.qty {
      display: inline-block;

      @include max-screen($bp-desktop-s) {
        margin: 0 14px 0 0;
      }
      @include min-screen($bp-desktop-s) {
        margin: 0 $trim 0 0;
      }
    }

    .actions {
      display: inline-block;

      @include max-screen($bp-desktop-s) {
        width: calc(100% - 74px);
      }

      .btn {
        width: 100%;
        padding: 11px $trim--xl;
      }
    }
  }
}

//  Product tabs
.product.info.detailed {
  @include min-screen($bp-desktop-s) {
    width: calc(66.666% - 12px);
    float: left;
    clear: left;
  }
}

.sales-block {
  @include min-screen($bp-desktop-s) {
    width: calc(33.333% - 12px);
    float: right;
    clear: right;
  }
}


.product-page-tabs {

  h3 {
    color: $c-dark;
    margin-bottom: 14px;
  }

  .value {
    p:last-child {
      margin-bottom: 0;
    }
  }
}