.box {

  &--classic {
    border: 1px solid $c-border;

    @include max-screen ($bp-tablet) {
      padding: 18px;
      margin-bottom: $trim--s;
    }

    @include screen ($bp-tablet,$bp-desktop-s) {
      padding: 18px $trim--l;
    }

    @include min-screen ($bp-tablet) {
      margin-bottom: $trim--l;
    }

    @include min-screen ($bp-desktop-s) {
      padding: $trim--l $trim--xl;
    }

    h5 {
      color: $c-blue;
      font-weight: 700;
    }

    p:last-child {
      margin: 0;
    }
  }

  &--center {
    border: 1px solid $c-border;

    @include max-screen ($bp-tablet) {
      padding: 18px;
      margin-bottom: $trim;
    }
    @include screen ($bp-tablet,$bp-desktop-s) {
      padding: $trim--l;
      margin-bottom: 18px;
    }
    @include min-screen ($bp-desktop-s) {
      padding: $trim--xl;
      margin-bottom: $trim--l;
    }
  }
}