.our-services {

  border-top: 1px solid $c-border;
  border-bottom: 1px solid $c-border;

  .service-container {
    text-align: center;

    @include min-screen($bp-tablet){
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    .service-item {
      position: relative;
      color: $c-blue;
      font-weight: 700;
      padding: $trim 0;

      @include min-screen($bp-tablet) {
        flex: 1;

        &:first-child {
          text-align: left;
        }
      }

      @include max-screen($bp-tablet) {
        &:not(#i-qua) {
          display: none;
        }
      }
      @include screen($bp-tablet,$bp-desktop-s) {
        &:nth-child(n+4) {
          display: none;
        }

        &:nth-child(3) {
          text-align: right;
        }
      }

      @include min-screen($bp-desktop-s) {
        &:last-child {
          text-align: right;
        }
      }

      @include screen($bp-desktop-s,$bp-desktop) {
        &#i-eco {
          display: none;
        }
      }

      @include min-screen($bp-desktop) {
        &#i-eco-mobile {
          display: none;
        }
      }

      &:before {
        vertical-align: middle;
        margin-right: $trim--xs;
      }

      &:not(:first-child):after {

        @include min-screen($bp-tablet) {
          content: '';
          position: absolute;
          left: 0;
          top: 10px;
          height: 32px;
          width: 1px;
          background: $c-border;
        }
      }

      &#i {
        &-del {
          &:before { content: url(../images/icons/icon-delivery.svg); }
        }
        &-qua {
          &:before { content: url(../images/icons/icon-star.svg); }
        }
        &-eco,
        &-eco-mobile {
          &:before { content: url(../images/icons/icon-ecosystem.svg); }
        }
        &-cert {
          &:before { content: url(../images/icons/icon-isocert.svg); }
        }
      }
    }
  }
}