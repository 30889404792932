// breakpoints
$bp-tablet    : 768px;
$bp-desktop-s : 1024px;
$bp-desktop   : 1440px;

//wrapper
$s-wrapper  : 1116px;

//trims
$trim       : 16px;
$trim--xs   : $trim / 2;      // 8px
$trim--s    : 12px;           // 12px
$trim--m    : 18px;           // 18px
$trim--l    : $trim * 1.5;    // 24px
$trim--xl   : $trim * 2;      // 32px
$trim--xxl  : $trim * 2.25;   // 36px
$trim--xxxl : $trim * 3;      // 48px

//border
$b-radius   : 4px;

//fonts
$f-small    : 12px;
$lh-small   : 14px;

$f-medium   : 14px;
$lh-medium  : 18px;