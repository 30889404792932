.section-about {

  .about-item {
    border-bottom: 1px solid $c-border--reverse;
    padding: 10px 0;

    &:first-child {
      border-top: 1px solid $c-border--reverse;
    }

    h2 {
      position: relative;
      margin: 0;

      &:after {
        content: url(../images/icons/icon-down.svg);
        position: absolute;
        top: 0;
        right: 0;
        line-height: 22px;
      }
    }

    &.active {

      h2 {
        margin-bottom: $trim;

        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }
}