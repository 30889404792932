//  Catalog toolbar
.toolbar-products {
  .pages-items {
    .item {
      margin: 0;
      width: 40px;
      text-align: center;
      border: 1px solid $c-border;
      background-color: $c-white;

      &.current {
        background-color: $c-pink;
        
        .page {
          color: $c-dark;
        }
      }

      a.page {
        color: $c-opacity;
      }

      &:not(:last-child) {
        border-right: 0;
      }
      
      &:not(.pages-item-previous):not(.current):not(.pages-item-next) {
        @include max-screen($bp-desktop-s) {
          display: none;
        }
      }

      .page,
      .action {
        padding: 12px;
        line-height: 14px;
        font-size: 14px;
      }

      .action {
        margin: 0;
        border: 0;

        &:before {
          margin-left: -15px;
          font-size: 40px;
        }
      }
    }
  }
}