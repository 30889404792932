.block-suggested-products {

  border-top: 1px solid $c-border;

  h2 {
    color: $c-blue;
  }

  li.product-item {
    @include max-screen($bp-tablet){
      @include float-item(2,$trim);
    }

    @include screen($bp-tablet,$bp-desktop-s){
      @include float-item(3,$trim--l);
    }

    @include min-screen($bp-desktop-s){
      @include float-item(4,$trim--l);
    }
  }
}