// --- float item ---------------------------------------------------------------

@mixin float-item($columns,$space) {
  float: left;
  box-sizing: border-box;
  width: calc((100% / #{$columns}) - (#{$space} - #{$space} / #{$columns}));
  margin-bottom: $space;

  &:not(:nth-child(#{$columns}n+#{$columns})) {
    margin-right: $space;
  }
}

// --- flex item ---------------------------------------------------------------
@mixin flex-item($columns,$space) {
  width: calc((100% / #{$columns}) - (#{$space} - #{$space} / #{$columns}));

  &:not(:nth-child(#{$columns}n+#{$columns})) {
    margin-right: $space;
  }
}


// --- buttons ---------------------------------------------------------------

@mixin btn-style($bg, $shadow){
  background: $bg;
  box-shadow: 0 4px 12px 0 $shadow;
  border: none;
  color: $c-white !important;
  text-decoration: none;
}

@mixin button($bg, $shadow) {
 @include btn-style($bg, $shadow);

  &:hover,
  &:focus,
  &:active {
    @include btn-style($bg, $shadow);
  }
}


// --- screen ---------------------------------------------------------------

@mixin mq($args...) {
  $media-type: 'only screen';
  $media-type-key: 'media-type';
  $args: keywords($args);
  $expr: '';
  @if map-has-key($args, $media-type-key) {
    $media-type: map-get($args, $media-type-key);
    $args: map-remove($args, $media-type-key);
  }
  @each $key, $value in $args {
    @if $value {
      $expr: "#{$expr} and (#{$key}: #{$value})";
    }
  }
  @media #{$media-type} #{$expr} {
    @content;
  }
}

@mixin screen($min, $max, $orientation: false) {
  @include mq($min-width: $min, $max-width: $max - 1, $orientation: $orientation) {
    @content;
  }
}
@mixin max-screen($max) {
  @include mq($max-width: $max - 1) {
    @content;
  }
}
@mixin min-screen($min) {
  @include mq($min-width: $min) {
    @content;
  }
}