.block-section {
  background: $c-dark;
  color: $c-text--reverse;

  @include min-screen($bp-desktop-s) {
    display: flex;
    align-items: center;
  }

  h2 {
    color: $c-white;
  }

  .block-half {
    @include min-screen($bp-desktop-s) {
      flex: 1;
    }
  }

  .section-content {
    box-sizing: border-box;

    @include max-screen($bp-tablet) {
      padding: $trim--l $trim $trim;
    }

    @include screen($bp-tablet,$bp-desktop-s) {
      padding: $trim--xl $trim--xxl;
    }

    @include min-screen($bp-desktop-s) {
      width: $s-wrapper / 2;
      float: right;
    }

    @include screen($bp-desktop-s, $bp-desktop) {
      padding: $trim--xl;
    }

    @include min-screen($bp-desktop) {
      padding: 42px 42px 42px 0;
    }
  }

  .section-img {
    position: relative;
    overflow: hidden;
    line-height: 0;

    @include max-screen($bp-tablet) {
      height: 100px;
    }

    @include screen($bp-tablet,$bp-desktop-s) {
      height: 240px;
    }

    @include min-screen($bp-desktop-s) {
      height: 480px;
    }

    img {
      width: 100%;
      min-height: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      @include screen($bp-desktop-s, $bp-desktop) {
        height: 100%;
        width: auto;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}