.language-change {

  .view {

    &-ukgbp {

      a:before,
      span:before {
        content: '';
        display: inline-block;
        background-image: url(../images/gb.png);
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;
        top: 2px;
        width: 19px;
        height: 14px;
        margin-right: 4px;
      }
    }
  }
}