.block-servers {
  border-bottom: 1px solid $c-border;

  .container {
    @include min-screen($bp-desktop) {
      padding-top: $trim--xxxl;
      padding-bottom: $trim--xxxl;
    }
  }

  h2 {
    color: $c-blue;
  }

  .servers-content {
    position: relative;
  }

  .servers-items {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .servers-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;

    border: 1px solid $c-border;

    @include max-screen($bp-tablet) {
      padding: $trim--xs;
      margin-bottom: $trim;
      -webkit-flex-basis: 48.5%;
      flex-basis: 48.5%;

      &:nth-child(n+3) {
        display: none;
      }
    }

    @include screen($bp-tablet, $bp-desktop-s) {
      padding: $trim--s;
      -webkit-flex-basis: 31.8%;
      flex-basis: 31.8%;

      &:nth-child(n+4) {
        display: none;
      }
    }

    @include max-screen($bp-desktop-s) {
      font-size: 10px;
    }

    @include min-screen($bp-desktop-s) {
      font-size: $f-small;
      padding: $trim;
      -webkit-flex-basis: 23.5%;
      flex-basis: 23.5%;

      &:nth-child(n+5) {
        display: none;
      }
    }

    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }

    a {
      text-decoration: none;
    }

    h4 {
      color: $c-blue;
      z-index: 100;

      &:hover {
        overflow: visible;
        height: auto;
      }

    }

    ul {
      padding-left: 14px;

      li {
        margin: 0;
      }
    }

    .item-price {
      color: $c-blue;
      font-size: 16px;
      font-weight: 700;
      margin-top: auto;

      @include max-screen($bp-tablet) {
        margin-bottom: $trim--xs;
      }
      @include screen($bp-tablet, $bp-desktop-s) {
        margin-bottom: $trim--s;
      }
      @include min-screen($bp-desktop-s) {
        margin-bottom: $trim;
      }
    }

    .btn {
      width: 100%;
      font-weight: 400;
    }
  }
}