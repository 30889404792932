.sales-block {
  background-color: $c-dark;

  h2 {
    color: $c-white;

    @include max-screen ($bp-desktop) {
      margin: 0 0 $trim--s;
    }
    @include min-screen ($bp-desktop) {
      margin: 0 0 14px;
    }
  }

  p {
    color: $c-text--reverse;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include max-screen ($bp-tablet) {
    padding: 18px;
  }

  @include screen ($bp-tablet,$bp-desktop) {
    padding: $trim--l;
  }

  @include min-screen ($bp-desktop) {
    padding: $trim--l $trim--xl;
  }
}