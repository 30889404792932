@font-face {
  font-family: "Helvetica Neue Custom";
  src: url('../fonts/helveticaneue/HelveticaNeue-01.ttf') format('truetype');
}
@font-face {
  font-family: "Helvetica Neue Custom";
  src: url('../fonts/helveticaneue/HelveticaNeue-Bold-02.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: "Helvetica Neue Custom";
  src: url('../fonts/helveticaneue/HelveticaNeue-BoldItalic-04.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Helvetica Neue Custom";
  src: url('../fonts/helveticaneue/HelveticaNeue-Italic-03.ttf') format('truetype');
  font-style: italic;
}

body {
  font-family: 'Helvetica Neue Custom';
  font-size: $f-medium;
  line-height: $lh-medium;
  font-weight: 400;
  background-color: $c-white;
  color: $c-text;
}


h1 {
  font-weight: 500;
  margin: 0 0 $trim;

  @include max-screen($bp-desktop-s) {
    font-size: 24px;
    line-height: 29px;
  }

  @include min-screen($bp-desktop-s) {
    font-size: 32px;
    line-height: 39px;
  }
}

h2 {
  font-weight: 500;

  @include max-screen($bp-tablet) {
    margin: $trim 0;
  }

  @include screen($bp-tablet,$bp-desktop-s) {
    margin: $trim--l 0;
  }

  @include max-screen($bp-desktop-s) {
    font-size: 18px;
    line-height: 23px;
  }

  @include min-screen($bp-desktop-s) {
    font-size: 24px;
    line-height: 29px;
    margin: $trim--xl 0;
  }
}

h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  @include max-screen($bp-desktop-s) {
    margin: 0 0 $trim--xs;
  }

  @include min-screen($bp-desktop-s) {
    margin: 0 0 $trim--s;
  }
}

h4 {
  font-size: $f-medium;
  line-height: $lh-medium;
  font-weight: 700;
  margin: 0 0 $trim--xs;
}

h5 {
  font-size: $f-medium;
  line-height: $lh-medium;
  font-weight: 400;
  margin: 0 0 $trim--s;
}

p {
  margin-bottom: $trim;
}

a,
button {
  font-family: 'Helvetica Neue Custom';
  font-size: $f-medium;
  line-height: $lh-medium;
  font-weight: 400;
}