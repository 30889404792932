ul.nav.items {

  @include max-screen($bp-tablet) {
    margin-left: -$trim;
    margin-right: -$trim;
  }

  @include screen($bp-tablet,$bp-desktop-s) {
    margin-left: -$trim--xl;
    margin-right: -$trim--xl;
  }

  @include max-screen($bp-desktop-s){

    position: relative;
    padding-top: 42px;

    .nav.item {

      @include screen($bp-tablet,$bp-desktop-s) {
        padding-left: 16px;
        padding-right: 16px;
      }

      &.current {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        cursor: pointer;

        background-color: $c-white;
        border-top: 1px solid $c-border;
        border-bottom: 1px solid $c-border;

        strong {
          position: relative;
          color: $c-dark;

          &:after {
            content: url(../images/icons/icon-down-dark.svg);
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 0;
            padding: 12px 16px;
          }
        }
      }

      &:not(.current) {
        display: none;
      }
    }

    &.sidebar-visible {

      .nav.item {
        display: block;

        &.current strong:after {
          transform: rotate(180deg);
        }
      }
    }
  }
}