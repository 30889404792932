.wrapper.container {
  
  .block-container {
    position: relative;

    > .btn {
      @include min-screen($bp-tablet) {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  h2 {
    display: inline-block;
    margin: 0 0 $trim;

    &.inline {
      @include screen($bp-tablet,$bp-desktop){
        margin: 7px 0 31px;
      }
      @include min-screen($bp-desktop){
        margin: 4px 0 36px;
      }
    }
  }
}