[data-role^="title"] {
  cursor: pointer;
}

// select
select.qty {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0 $trim;
  background-image: url('../images/icons/icon-arrow-drop-down.svg');
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: right;
}

// input type radio
.input-radio {
  width: 16px;
  height: 16px;
  display: block;
  border: 1px solid $c-border;
  border-radius: 100%;
  padding: 3px;

  .selected {
    background: $c-dark;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
  }
}

#parent_freeshipping_freeshipping {
  .input-radio .freeshipping_freeshipping {
    background: $c-dark;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
  }
}

//images
figure {
  font-size: 0;
  line-height: 0;
}
img {
  max-width: none;
}

// iframes
.iframe-container {

  iframe {
    width: 100%;
    height: 100%;
  }

}

// clearfix
.clearfix {

  &:before,
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

//wrapper
.wrapper {
  margin: 0 auto;

  @include max-screen($bp-tablet) {
    padding-left: $trim;
    padding-right: $trim;
  }

  @include screen($bp-tablet,$bp-desktop){
    padding-left: $trim--xxl;
    padding-right: $trim--xxl;
    max-width: calc(#{$s-wrapper} + (#{$trim--xxl} * 2));
  }

  @include min-screen($bp-desktop){
    padding-left: 0;
    padding-right: 0;
    max-width: $s-wrapper;
  }

  &.container {
    @include max-screen($bp-tablet) {
      padding-top: $trim--l;
      padding-bottom: $trim--l;
    }

    @include screen($bp-tablet,$bp-desktop-s){
      padding-top: $trim--xl;
      padding-bottom: $trim--xl;
    }

    @include min-screen($bp-desktop-s){
      padding-top: $trim--xl * 2;
      padding-bottom: $trim--xl * 2;
    }
  }
}

//typo
.page-title {
  color: $c-blue;

  @include max-screen($bp-tablet){
    margin: $trim--m 0 $trim--l;
  }
  @include screen($bp-tablet,$bp-desktop-s){
    margin: $trim--l 0;
  }

  @include min-screen($bp-desktop-s){
    margin: $trim--xl 0 64px;
  }
}

// blocks
.column.main {

  .block:not(.shipping):not(.discount):not(.items-in-cart),
  .form:not(.form-login):not(.form-cart):not(.form-shipping-address):not(.methods-shipping):not(.payments):not(.form-discount) {
    border: 1px solid $c-border;

    @include max-screen($bp-desktop-s) {
      padding: $trim;
    }

    @include min-screen($bp-desktop-s) {
      padding: $trim--xl;
    }

    > .block-title,
    > .fieldset .legend {

      @include max-screen($bp-desktop-s) {
        margin-bottom: $trim;
      }

      @include min-screen($bp-desktop-s) {
        margin-bottom: $trim--l;
      }

      a,
      span,
      strong {
        font-weight: 700;
        color: $c-blue;

        @include max-screen($bp-desktop-s){
          font-size: 18px;
          line-height: 23px;
        }

        @include min-screen($bp-desktop-s){
          font-size: 24px;
          line-height: 29px;
        }
      }
    }
  }
}