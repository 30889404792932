.cms-page-banner {
  display: inline-block;
  width: 100%;

  @include min-screen($bp-desktop) {
    margin-bottom: $trim--xl;
  }

  img {
    width: 100%;
  }
}