.page-footer {

  color: $c-text--reverse;
  background-color: $c-dark;

  a {
    color: $c-text--reverse;
  }

  .footer {
    &.content {
      border: none;
      margin-top: 0;

      @extend .wrapper;
      @extend .container;
      @extend .clearfix;
    }

    &-links {
      border-bottom: 1px solid $c-border--reverse;

      @include max-screen($bp-tablet){
        margin-bottom: $trim--l;
      }
      @include screen($bp-tablet,$bp-desktop-s){
        margin-bottom: $trim--xl;
      }
      @include min-screen($bp-desktop-s){
        margin-bottom: $trim--xxxl;
      }

      .links-box {

        @include max-screen($bp-tablet){
          margin-bottom: $trim--l;
        }
        @include screen($bp-tablet,$bp-desktop-s){
          margin-bottom: $trim--xl;
        }
        @include min-screen($bp-desktop-s){
          margin-bottom: $trim--xxxl;
          float: left;
          width: 50%;
        }

        a {
          font-weight: 700;
          display: block;
          margin-bottom: $trim--s;
        }

        &.categories {
          a {
            text-decoration: none;

            @include min-screen($bp-tablet){
              float: left;
              width: 50%;
            }
          }
        }

        &.posts {
          a {
            text-decoration: underline;
          }
        }

        //.half {
        //  @include min-screen($bp-tablet){
        //    width: 50%;
        //    float: left;
        //  }
        //}
      }
    }

    &-contact {
      @include max-screen($bp-tablet){
        margin-bottom: $trim--l;
      }
      @include min-screen($bp-tablet){
        float: left;
        width: 68%;
      }

      a {
        text-decoration: underline;
      }

      .side {
        @include min-screen($bp-tablet){
          width: 50%;
          float: left;
          padding-right: $trim;
        }
      }
    }

    &-bottom {
      background-color: $c-black;
      padding: 14px 0;

      @include max-screen($bp-tablet){
        text-align: center;
      }
    }
  }

  .block.newsletter {

    @include min-screen($bp-desktop-s){
      > * {
        padding-left: 25%;
      }
    }

    .title strong {
      font-weight: 400;
    }

    .field {
      margin-bottom: $trim--xs;

      @include min-screen($bp-desktop-s){
        float: left;
        width: calc(100% - 67px);
      }
    }

    .actions {
      @include min-screen($bp-desktop-s){
        float: right;
      }
    }

    input {
      height: 36px;
    }

    button {
      @extend .btn;
      @extend .btn--secondary;

      @include max-screen($bp-desktop-s){
        width: 100%;
      }
    }
  }

  .copyright {
    display: block;
    font-size: $f-medium;

    @include max-screen($bp-tablet){
      margin-bottom: $trim;
    }

    @include min-screen($bp-tablet){
      width: 50%;
      float: left;
      padding-right: $trim;
    }
  }

  .credit-cards {
    @include min-screen($bp-tablet){
      width: 50%;
      float: left;
      text-align: right;
    }
  }

  .footer_cms_links{
    float: left;
    width: 100%;
    margin-top: 20px;
  }
}