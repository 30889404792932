//  2 columns - right sidebar

.page-layout-2columns-right {

  &.blog-index-index,
  &.blog-post-view{

    .column.main {
      @include max-screen($bp-desktop-s){
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
      }
      @include min-screen($bp-desktop-s){
        width: 71%;
        padding-right: 70px;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 88px;
          width: 1px;
          background: $c-border;
        }
      }
    }

    .sidebar-additional {
      @include max-screen($bp-desktop-s){
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
      }
      @include min-screen($bp-desktop-s) {
        width: 25%;
        padding: 0;
      }
    }
  }
}