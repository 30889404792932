// listing
.post-list {
  padding: 0;

  @include min-screen($bp-tablet){
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
  }

  .post-holder {
    border: 1px solid $c-border;
    margin-bottom: $trim--l;

    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;

    @include min-screen($bp-tablet){
      -webkit-flex-basis: calc(50% - 12px);
      flex-basis: calc(50% - 12px);
      margin-right: $trim--l;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  .post-header {
    margin-left: -1px;
    margin-right: -1px;
    margin-top: -1px;
  }

  .post-ftimg-hld {

    a {
      display: block;
      position: relative;
      overflow: hidden;

      @include max-screen($bp-tablet){
        height: 130px;
      }
      @include screen($bp-tablet,$bp-desktop-s){
        height: 152px;
      }
      @include min-screen($bp-desktop-s){
        height: 164px;
      }
    }

    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }
  }

  .post-content {
    @extend .box--classic;
    border: none;
    margin: 0;

    -webkit-box-flex: 1;
    flex: 1;

    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;

    .post-title {
      a {
        color: inherit;
        font-weight: 700;
        text-decoration: none;
      }
    }

    .post-text-hld {
      margin-bottom: $trim--s;
    }

    .post-info {

      margin-top: auto;
      margin-bottom: $trim;

      .label,
      .dash,
      .item.post-categories {
        display: none;
      }
    }

    .post-read-more {
      width: 100%;
      text-decoration: none;
    }
  }
}

//sidebar
.widget.block-categories {

  .block-title {
    margin-bottom: 14px;

    @include max-screen($bp-desktop-s){
      display: none;
    }
  }

  .mobile-switcher {
    position: relative;
    color: $c-dark;
    font-weight: 700;
    border: 1px solid $c-border;
    padding: 10px $trim;

    &:after {
      content: url(../images/icons/icon-down-dark.svg);
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      padding: 10px $trim;
    }

    @include min-screen($bp-desktop-s){
      display: none;
    }
  }

  ul.accordion {
    @include max-screen($bp-desktop-s){
      display: none;
    }
  }

  li {
    margin-bottom: 1px;

    a {
      background: $c-pink;
      color: $c-dark;
      padding: 11px 16px;
      display: block;
      text-decoration: none;
    }
  }

  &.sidebar-visible {
    .mobile-switcher {
      &:after {
        transform: rotate(180deg);
      }
    }

    ul.accordion {
      display: block;
    }
  }
}

//  category page
.blog-category-view {

  .category-content {
    img {
      width: 100%;
    }

    .box--center {
      background: $c-highlight;
      border: none;
    }
  }

  .post-list {
    .post-holder {
      @include min-screen($bp-desktop-s){
        -webkit-flex-basis: calc(33.333% - 16px);
        flex-basis: calc(33.333% - 16px);

        &:nth-child(2n) {
          margin-right: $trim--l;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
}

// article page
.post-view {
  .post-info {
    .label,
    .dash,
    .item.post-categories {
      display: none;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    color: $c-blue;
  }

  .post-holder {
    @include min-screen($bp-desktop-s){
      position: relative;
    }

    .post-header {
      @include max-screen($bp-tablet){
        margin-bottom: $trim--m;
      }
      @include min-screen($bp-tablet){
        margin-bottom: $trim--l;
      }
    }
  }
}